import { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import {
  GetAutomationDetails,
  GetAutomationDetailsVariables,
} from 'types/graphql'

import { StatusLabel } from 'src/components/CustomerIntegrations/IntegrationCard'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Authors, {
  AuthorsDateFormat,
} from 'src/components/Library/Authors/Authors'
import Loading from 'src/components/Library/Loading/Loading'

import { AutomationCategoryOption } from '../AutomationModals/AutomationCategoryModal'
import { GET_AUTOMATION_DETAILS } from '../queries'

import EditAutomationDetailsForm from './EditAutomationDetailsForm'

export type AutomationQueryType = GetAutomationDetails['automation']

interface AutomationDetailProps {
  automationId: number
}

const AutomationDetails: FC<AutomationDetailProps> = ({ automationId }) => {
  const [automationDetails, setAutomationDetails] =
    useState<AutomationQueryType>(null)
  const [automationDetailsLoading, setAutomationDetailsLoading] =
    useState<boolean>(true)

  const [automationCategories, setAutomationCategories] =
    useState<AutomationCategoryOption[]>(null)

  const [getAutomationDetails] = useLazyQuery<
    GetAutomationDetails,
    GetAutomationDetailsVariables
  >(GET_AUTOMATION_DETAILS, {
    onCompleted: (data) => {
      const automationCategoryOptions: AutomationCategoryOption[] =
        data?.automationCategories.reduce(
          (categoryOptions, currentCategory) => {
            // Push in the Category
            categoryOptions.push({
              id: currentCategory.id,
              label: currentCategory.name,
              parentId: null,
            })

            // Push in the Sub Category
            for (const subcategory of currentCategory.childCategories) {
              categoryOptions.push({
                id: subcategory.id,
                label: subcategory.name,
                parentId: currentCategory.id,
              })
            }

            return categoryOptions
          },
          [],
        )

      setAutomationCategories(automationCategoryOptions)

      setAutomationDetails(data?.automation)

      setAutomationDetailsLoading(false)
    },
    onError: () => {
      setAutomationDetailsLoading(false)
    },
  })

  useEffect(() => {
    const fetchAutomationDetails = async (id) => {
      await getAutomationDetails({ variables: { id } })
    }

    // If there is an attached automation id - search for it
    if (automationId) {
      setAutomationDetailsLoading(true)
      fetchAutomationDetails(automationId)
    }
  }, [automationId])

  return (
    <>
      {automationDetailsLoading && (
        <div className="grid grow place-items-center">
          <Loading />
        </div>
      )}
      {!automationDetailsLoading && (
        <div className="w-full">
          <div className="p-6">
            <div className="flex items-start justify-between gap-4">
              <p className="text-xl">{automationDetails?.name}</p>
              <div className="pt-1">
                <StatusLabel active={automationDetails?.active} />
              </div>
            </div>
            <p className="pt-1 font-light text-gray-400">
              {automationDetails?.description}
            </p>
            <div className="pt-3">
              <Authors
                byText="Created/Edited by"
                createdAt={dayjs(automationDetails?.createdAt).format(
                  AuthorsDateFormat.day,
                )}
                createdByUser={automationDetails?.createdByUser}
                updatedAt={dayjs(automationDetails?.updatedAt).format(
                  AuthorsDateFormat.day,
                )}
                updatedByUser={automationDetails?.updatedByUser}
              />
            </div>
          </div>

          <Accordion
            expanded={true}
            title={<p className="text-lg font-light">Update Details</p>}
            customstyle="before:hidden !border-0 bg-white py-2 !rounded-0"
            borderstyle="!border-y before:hidden !rounded-0"
          >
            <EditAutomationDetailsForm
              automation={automationDetails}
              automationCategoryOptions={automationCategories}
            />
          </Accordion>
        </div>
      )}
    </>
  )
}

export default AutomationDetails
