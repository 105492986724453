import { useEffect, useRef } from 'react'

import { AutomationUserNotificationDataType } from '@stafflink/zod-types/src/automations'
import { Blockquote } from '@tiptap/extension-blockquote'
import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Code } from '@tiptap/extension-code'
import { CodeBlock } from '@tiptap/extension-code-block'
import { Color } from '@tiptap/extension-color'
import { Document } from '@tiptap/extension-document'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import { FontFamily } from '@tiptap/extension-font-family'
import { Gapcursor } from '@tiptap/extension-gapcursor'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Highlight } from '@tiptap/extension-highlight'
import { History } from '@tiptap/extension-history'
import { HorizontalRule } from '@tiptap/extension-horizontal-rule'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { Mention } from '@tiptap/extension-mention'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Strike } from '@tiptap/extension-strike'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { TaskItem } from '@tiptap/extension-task-item'
import { TaskList } from '@tiptap/extension-task-list'
import { Text } from '@tiptap/extension-text'
import { TextAlign } from '@tiptap/extension-text-align'
import { TextStyle } from '@tiptap/extension-text-style'
import { Underline } from '@tiptap/extension-underline'
import {
  FontSize,
  HeadingWithAnchor,
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  RichTextEditor,
  type RichTextEditorRef,
  TableBubbleMenu,
  TableImproved,
} from 'mui-tiptap'
import { BaserowField } from 'types/graphql'

import { createMentionSuggestionOptions } from 'src/components/Automations/AutomationActions/NotificationActionStepComponentEmailMessage/mentionSuggestionOptions'

import EditorMenuControls from './EditorMenuControls'

interface NotificationActionStepComponentEmailMessageProps {
  actionStep: AutomationUserNotificationDataType
  handleUpdateMessageText: (value: string) => void
  availableFields: BaserowField[]
  editable?: boolean
  showMenuBar?: boolean
  className?: string
}

const NotificationActionStepComponentEmailMessage = ({
  actionStep,
  handleUpdateMessageText,
  availableFields,
  editable = true,
  showMenuBar = true,
  className = '',
}: NotificationActionStepComponentEmailMessageProps) => {
  const rteRef = useRef<RichTextEditorRef>(null)

  const decodeEntities = (html: string) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = html
    return textArea.value
  }

  useEffect(() => {
    if (!editable && rteRef.current && actionStep?.notificationMessage) {
      const editor = rteRef.current.editor
      editor.commands.setContent(actionStep.notificationMessage, false)
    }
  }, [actionStep?.notificationMessage, editable])

  return (
    <RichTextEditor
      ref={rteRef}
      extensions={[
        TableImproved.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Link.configure({ openOnClick: true }),
        BulletList,
        CodeBlock,
        Document,
        HardBreak,
        ListItem,
        OrderedList,
        Paragraph,
        Text,
        Bold,
        Blockquote,
        Code,
        Italic,
        Underline,
        Strike,
        LinkBubbleMenuHandler,
        Gapcursor,
        HeadingWithAnchor,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'image'],
        }),
        TextStyle,
        Color,
        FontFamily,
        FontSize,
        Highlight.configure({ multicolor: true }),
        HorizontalRule,
        Dropcursor,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Mention.configure({
          suggestion: createMentionSuggestionOptions(availableFields),
        }),

        Placeholder.configure({
          placeholder: 'Email Message -> Use @ To Insert A Field Value.',
        }),
        History,
      ]}
      content={actionStep?.notificationMessage}
      renderControls={() => {
        if (showMenuBar) {
          return <EditorMenuControls />
        }
      }}
      className={`${className} w-full bg-white`}
      RichTextFieldProps={{
        variant: 'outlined',
        className: `${className} w-full px-4 bg-white`,
      }}
      onUpdate={({ editor }) => {
        const rawHTML = decodeEntities(editor.getHTML())
        handleUpdateMessageText(rawHTML)
      }}
      editable={editable}
    >
      {() => (
        <>
          <LinkBubbleMenu />
          <TableBubbleMenu />
        </>
      )}
    </RichTextEditor>
  )
}

export default NotificationActionStepComponentEmailMessage
