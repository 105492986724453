import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { LazyQueryExecFunction } from '@apollo/client'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  Action,
  ActionData,
  ActionExecutionType,
  AutomationUserNotificationDataType,
} from '@stafflink/zod-types/src/automations'
import {
  BaserowField,
  BaserowTableFieldInput,
  BaserowWorkspaces,
  Exact,
  GetBaserowTableFields,
} from 'types/graphql'

import ActionTypeOptions from 'src/components/Automations/AutomationActions/ActionTypeOptions'
import NotificationActionStepComponent from 'src/components/Automations/AutomationActions/NotificationActionStepComponent'
import { CellAutomation } from 'src/components/Automations/AutomationCell'
import { BaserowObject } from 'src/components/Automations/utils'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button/Button'

import { getBaseOptions } from '../AutomationEditForms/AutomationEditTriggerForm'

import BaserowActionStepComponent from './BaserowActionStepComponent'

const getMatchedBaserowItems = (
  baserowData: BaserowWorkspaces[],
  matchTableId: string | number,
): {
  table: BaserowObject
  base: BaserowObject
  workspace: BaserowObject
} => {
  if (matchTableId) {
    const matchedTable = baserowData
      ?.map((workspace) => {
        return workspace?.databases?.map((base) => {
          return base?.tables?.find(
            (table) => table?.id?.toString() === matchTableId?.toString(),
          )
        })
      })
      .flat(2)
      .find((table) => table)

    const matchedBase = baserowData
      ?.map((workspace) => {
        return workspace?.databases?.find((base) => {
          return base?.tables?.find(
            (table) => table?.id?.toString() === matchTableId?.toString(),
          )
        })
      })
      .find((base) => base)

    const matchedWorkspace = baserowData?.find((workspace) => {
      return workspace?.databases?.find((base) => {
        return base?.tables?.find(
          (table) => table?.id?.toString() === matchTableId?.toString(),
        )
      })
    })

    return {
      table: {
        label: matchedTable?.name,
        value: matchedTable?.id,
      },
      base: {
        label: matchedBase?.name,
        value: matchedBase?.id,
      },
      workspace: {
        label: matchedWorkspace?.workspaceName,
        value: matchedWorkspace?.workspaceId,
      },
    }
  } else {
    return {
      table: null,
      base: null,
      workspace: null,
    }
  }
}

interface ActionComponentProps {
  actionItem: Action
  onUpdate: (updatedReq: any /** Not typed */) => void
  actionIndex: number
  workspacesAndBases: BaserowWorkspaces[]
  GetTableFields: LazyQueryExecFunction<
    GetBaserowTableFields,
    Exact<{ input: BaserowTableFieldInput }>
  >
  loadingTableFields: boolean
  triggerTableFields?: BaserowField[]
  triggerWorkspace: BaserowObject
  triggerBase: BaserowObject
  triggerTable: BaserowObject
  useAdvancedLogic: boolean
  advancedLogicTableId: number
  selectedAutomation: CellAutomation
  setIsAutomationLooping: Dispatch<SetStateAction<boolean>>
  isAutomationLooping: boolean
  setLoopingError: Dispatch<SetStateAction<string>>
  loopingError: string
}
const ActionComponent: FC<ActionComponentProps> = ({
  actionItem,
  actionIndex,
  onUpdate,
  workspacesAndBases,
  GetTableFields,
  loadingTableFields,
  triggerTableFields,
  triggerWorkspace,
  triggerBase,
  triggerTable,
  useAdvancedLogic,
  advancedLogicTableId,
  selectedAutomation,
  setIsAutomationLooping,
  isAutomationLooping,
  setLoopingError,
  loopingError,
}) => {
  // Action Type
  const [selectedActionType, setSelectedActionType] =
    useState<ActionExecutionType>(actionItem?.action)

  // Form Inputs
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<BaserowObject>(null)

  const [selectedBase, setSelectedBase] = useState<BaserowObject>(null)
  const [selectedBaseInput, setSelectedBaseInput] = useState<string>('')

  const [selectedTable, setSelectedTable] = useState<BaserowObject>(null)
  const [selectedTableInput, setSelectedTableInput] = useState<string>('')

  const [availableTables, setAvailableTables] = useState<BaserowObject[]>([])
  const [availableBases, setAvailableBases] = useState<BaserowObject[]>([])

  // Saved table id does not appear in the fetched list
  const [tableMismatched, setTableMismatched] = useState<boolean>(false)
  const [availableFields, setAvailableFields] = useState<BaserowField[]>([])

  const createTableMatchesTriggerTable =
    selectedActionType === ActionExecutionType.CREATE &&
    selectedTable &&
    selectedWorkspace?.value === triggerWorkspace?.value &&
    selectedBase?.value === triggerBase?.value &&
    selectedTable?.value === triggerTable?.value

  const handleUpdateBaserowActionItem = (
    updatedReq: ActionData,
    index: number,
  ) => {
    const updatedNest = actionItem.data

    if (updatedReq === null) {
      updatedNest.splice(index, 1)
    } else {
      updatedNest[index] = updatedReq
    }

    const action = useAdvancedLogic
      ? ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC
      : actionItem?.action

    onUpdate({ ...actionItem, data: updatedNest, action })
  }

  const handleUpdateNotificationActionItem = (
    updatedReq: AutomationUserNotificationDataType,
    index: number,
  ) => {
    const updatedNest = actionItem.notifyUserData

    if (updatedReq === null) {
      updatedNest.splice(index, 1)
    } else {
      updatedNest[index] = updatedReq
    }

    onUpdate({ ...actionItem, notifyUserData: updatedNest })
  }

  const deleteActionStep = () => {
    onUpdate(null)
  }

  const addActionStep = () => {
    const newBaserowActionStep: ActionData = {
      fieldName: '',
      fieldId: null,
      fieldType: null,
      inputType: null,
    }
    const newNotificationsActionStep: AutomationUserNotificationDataType = {
      matchingData: {
        matchValues: [],
        matchOn: null,
        matchType: null,
      },
      notificationMessage: '',
      notificationTitle: '',
    }
    const updatedBaserowActionSteps: ActionData[] = [...actionItem.data]
    updatedBaserowActionSteps.push(newBaserowActionStep)

    const updatedNotificationsActionSteps: AutomationUserNotificationDataType[] =
      actionItem?.notifyUserData ? [...actionItem.notifyUserData] : []
    updatedNotificationsActionSteps.push(newNotificationsActionStep)

    const outputTableId = useAdvancedLogic
      ? advancedLogicTableId
      : actionItem?.outputTableId

    onUpdate({
      ...actionItem,
      data: [
        ActionExecutionType.CREATE,
        ActionExecutionType.UPDATE,
        ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
      ].includes(selectedActionType)
        ? updatedBaserowActionSteps
        : [],
      notifyUserData: [
        ActionExecutionType.SEND_NOTIFICATION,
        ActionExecutionType.SEND_EMAIL,
      ].includes(selectedActionType)
        ? updatedNotificationsActionSteps
        : [],
      action: selectedActionType,
      outputTableId,
    })
  }

  const handleChangeAction = (
    _event: React.MouseEvent<HTMLElement>,
    actionType: ActionExecutionType,
  ) => {
    if (actionType !== null) {
      setSelectedActionType(actionType)
      onUpdate({
        ...actionItem,
        action: useAdvancedLogic
          ? ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC
          : actionType,
      })
    }
  }

  useEffect(() => {
    if (workspacesAndBases) {
      // set the base options for all workspaces

      // Format the Bases into SelectOption objects
      const baseSelectOptions = getBaseOptions(workspacesAndBases)

      // Set the input options
      setAvailableBases(baseSelectOptions)
    }

    if (
      (actionItem?.outputTableId ||
        (useAdvancedLogic && advancedLogicTableId)) &&
      workspacesAndBases
    ) {
      const matchTableId = useAdvancedLogic
        ? advancedLogicTableId
        : actionItem?.outputTableId

      const matchedBaserowItemsToTableId = getMatchedBaserowItems(
        workspacesAndBases,
        matchTableId,
      )

      setSelectedWorkspace(matchedBaserowItemsToTableId.workspace)

      setSelectedBase(matchedBaserowItemsToTableId.base)

      // if the current selected table does not match what we match - update it
      if (selectedTable?.value !== matchedBaserowItemsToTableId.table?.value) {
        setSelectedTable(matchedBaserowItemsToTableId.table)
      }

      if (!matchedBaserowItemsToTableId.table?.value) {
        setTableMismatched(true)
      }
    }
  }, [actionItem, workspacesAndBases])

  useEffect(() => {
    if (selectedBase) {
      const baseObject = workspacesAndBases
        .find((workspace) => {
          return workspace?.workspaceId === selectedWorkspace?.value
        })
        ?.databases?.find((base) => {
          return base?.id === selectedBase?.value
        })

      const tables = baseObject?.tables
        ?.map((table) => {
          return {
            label: table.name,
            value: table.id,
          }
        })
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        )
      setAvailableTables(tables)
    }
  }, [selectedBase])

  const handleUpdateOutputTableId = (option) => {
    onUpdate({
      ...actionItem,
      outputTableId: useAdvancedLogic ? advancedLogicTableId : option?.value,
    })
  }

  useEffect(() => {
    const getTableFields = async () => {
      const { data } = await GetTableFields({
        variables: {
          input: {
            tableId: selectedTable.value?.toString(),
            workspaceId: selectedWorkspace.value?.toString(),
          },
        },
      })
      return data
    }

    if (selectedTable?.value) {
      getTableFields().then((data) => {
        const sortedData = [...data.getBaserowTableFields].sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })

        setAvailableFields(sortedData)
      })
    } else {
      setAvailableFields([])
    }
  }, [selectedTable])

  useEffect(() => {
    if (selectedActionType === ActionExecutionType.UPDATE) {
      // Select the Trigger Table
      setSelectedWorkspace(triggerWorkspace)
      setSelectedBase(triggerBase)
      setSelectedTable(triggerTable)
      handleUpdateOutputTableId(triggerTable)
    } else {
      setSelectedWorkspace(null)
      setSelectedBase(null)
      setSelectedTable(null)
      handleUpdateOutputTableId(null)
    }
  }, [selectedActionType])

  // Reset selected items on Advanced Logic change
  useEffect(() => {
    if (useAdvancedLogic) {
      setSelectedWorkspace(triggerWorkspace)
      setSelectedBase(triggerBase)
      setSelectedTable(triggerTable)
      handleUpdateOutputTableId(triggerTable)
    } else {
      setSelectedWorkspace(null)
      setSelectedBase(null)
      setSelectedTable(null)
      handleUpdateOutputTableId(null)
    }
  }, [useAdvancedLogic])

  useEffect(() => {
    if (useAdvancedLogic && advancedLogicTableId) {
      // we need to reselect the create/update table to be something else
      const matchedBaserowItemsToTableId = getMatchedBaserowItems(
        workspacesAndBases,
        advancedLogicTableId,
      )

      setSelectedWorkspace(matchedBaserowItemsToTableId.workspace)

      setSelectedBase(matchedBaserowItemsToTableId.base)

      setSelectedTable(matchedBaserowItemsToTableId.table)
    }
  }, [advancedLogicTableId])

  useEffect(() => {
    if (
      !useAdvancedLogic &&
      selectedAutomation?.onUpdateTrigger &&
      selectedActionType === ActionExecutionType.UPDATE &&
      !selectedAutomation?.matchCriteria['RECORD']?.selectedView &&
      selectedAutomation?.matchCriteria['RECORD']?.requirements?.length === 0
    ) {
      setIsAutomationLooping(true)
      setLoopingError(
        'Your automation is triggered on record updates but does not have any filters. If you update a record in the same table, you may cause an infinite loop. Please make sure you are performing the correct filters and operations.',
      )
    } else if (createTableMatchesTriggerTable && !useAdvancedLogic) {
      setIsAutomationLooping(true)
      setLoopingError(
        'Creating a new record in this table will cause an endless loop of automations. This happens because each new record triggers the automation to run again. This will rapidly deplete your available automation quota.',
      )
    } else {
      setIsAutomationLooping(false)
    }
  }, [
    selectedAutomation,
    selectedActionType,
    useAdvancedLogic,
    createTableMatchesTriggerTable,
  ])

  return (
    <Box className={'mt-4 w-full rounded border border-gray-300'}>
      <Stack spacing={1}>
        <Stack
          direction="column"
          spacing={1}
          className={'flex justify-between'}
        >
          <div className="!m-0 flex w-full items-center justify-between gap-4 p-4">
            <Typography className="font-bold">
              {`Step ${actionIndex + 1}`}
            </Typography>

            <Tooltip title={'Remove Action Step'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  className="min-w-0 rounded-full"
                  onClick={deleteActionStep}
                >
                  <XMarkIcon className="h-6 w-6 text-gray-500" />
                </Button>
              </div>
            </Tooltip>
          </div>

          <ActionTypeOptions
            selectedActionType={selectedActionType}
            handleChangeAction={handleChangeAction}
            useAdvancedLogic={useAdvancedLogic}
          />
          {isAutomationLooping && (
            <div className={'p-4'}>
              <Tooltip title={loopingError}>
                <div className="flex grow items-center gap-2 rounded border border-orange-300 bg-orange-50 p-2 text-sm text-orange-700">
                  <ExclamationTriangleIcon className="h-6 w-6" />
                  <p>Automation Loop Detected</p>
                </div>
              </Tooltip>
            </div>
          )}
        </Stack>
      </Stack>

      {(selectedActionType === ActionExecutionType.CREATE ||
        useAdvancedLogic) && (
        <>
          <div className="flex flex-col border-t border-gray-300 p-4">
            <div className="flex items-center justify-between gap-4">
              <p className="mb-2">
                {useAdvancedLogic ? 'Update Records In:' : 'Create Record In:'}
              </p>
              <div className="">
                {tableMismatched && (
                  <Tooltip
                    title={
                      'The original table in this automation has either been deleted you no longer have access. Please select a new table or check your permissions in Baserow.'
                    }
                  >
                    <div className="flex items-center gap-2 rounded border border-orange-300 bg-orange-50 p-2 text-sm text-orange-700">
                      <ExclamationTriangleIcon className="h-6 w-6" />
                      <p>Saved Table Mismatch</p>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            <FormInputRow label="Base">
              <FormControl
                className="w-full"
                disabled={availableBases?.length === 0 || useAdvancedLogic}
                error={!selectedBase}
              >
                <InputLabel
                  htmlFor="select-create-base"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {availableBases?.length === 0 && 'No Bases available'}
                </InputLabel>
                <Autocomplete
                  data-testid="action-component-select-base"
                  disabled={availableBases?.length === 0 || useAdvancedLogic}
                  value={selectedBase}
                  options={availableBases}
                  groupBy={(option) => option.group}
                  onChange={(
                    _event: React.ChangeEvent,
                    newValue: BaserowObject,
                  ) => {
                    setSelectedBase(newValue)
                    setSelectedWorkspace({
                      label: newValue.group,
                      value: newValue.groupId,
                    })
                    setAvailableTables([])
                    setSelectedTable(null)
                    handleUpdateOutputTableId(null)
                  }}
                  inputValue={selectedBaseInput}
                  onInputChange={(_event, newInputValue) => {
                    setSelectedBaseInput(newInputValue)
                  }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value && option.label === value.label
                  }
                  id="select-create-base"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //TODO Sam this is throwing an error to console
                      placeholder="Select a Base"
                      error={!selectedBase}
                    />
                  )}
                  renderGroup={(params) => (
                    <div key={params.key}>
                      <ListSubheader
                        className={'border-y bg-gray-50 text-gray-600'}
                      >
                        {params.group}
                      </ListSubheader>
                      <ul>{params.children}</ul>
                    </div>
                  )}
                  fullWidth
                  size="small"
                  disableClearable
                />
                <FormHelperText>
                  {!selectedBase && 'Select a Base'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>

            <FormInputRow label="Table">
              <FormControl
                className="w-full"
                disabled={availableTables?.length === 0 || useAdvancedLogic}
                error={!selectedTable}
              >
                <InputLabel
                  htmlFor="select-create-Table"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {availableTables?.length === 0 && 'No Tables available'}
                </InputLabel>
                <Autocomplete
                  disabled={availableTables?.length === 0 || useAdvancedLogic}
                  value={selectedTable}
                  options={availableTables}
                  onChange={(
                    _event: React.ChangeEvent,
                    newValue: BaserowObject,
                  ) => {
                    setSelectedTable(newValue)
                    handleUpdateOutputTableId(newValue)
                  }}
                  inputValue={selectedTableInput}
                  onInputChange={(_event, newInputValue) => {
                    setSelectedTableInput(newInputValue)
                  }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                  }}
                  id="select-create-Table"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //TODO Sam this is throwing an error to console
                      placeholder={
                        availableTables?.length > 0 && 'Select a Table'
                      }
                      error={!selectedTable}
                    />
                  )}
                  fullWidth
                  size="small"
                  disableClearable
                />
                <FormHelperText>
                  {!selectedTable && 'Select a Table'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
          </div>
        </>
      )}

      {loadingTableFields && (
        <div className="flex w-full items-center justify-center gap-4 border-t border-gray-300 p-4 text-indigo-600">
          <CircularProgress color="inherit" className="!h-8 !w-8" />
          <p className="text-gray-400">Loading Table Fields</p>
        </div>
      )}

      {!loadingTableFields &&
        [
          ActionExecutionType.CREATE,
          ActionExecutionType.UPDATE,
          ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
          ActionExecutionType.SEND_NOTIFICATION,
          ActionExecutionType.SEND_EMAIL,
        ].includes(selectedActionType) && (
          <>
            {!availableFields && (
              <div className="flex flex-col border-t border-gray-300 p-4 text-center text-gray-400">
                <p>No Fields Found</p>
              </div>
            )}

            {availableFields && (
              <div className="relative rounded">
                <div className="sticky top-[75px] z-[2] flex flex-col border-b border-t border-gray-300 bg-white p-4">
                  <div className="flex items-center justify-between gap-4">
                    <p>Add Action</p>
                    <Button
                      fullWidth={false}
                      variant="outlined"
                      className="min-w-[0] px-3 hover:bg-indigo-100"
                      onClick={() => {
                        addActionStep()
                      }}
                      startIcon={<PlusIcon className="h-5 w-5" />}
                    >
                      <span
                        className="pt-0.5"
                        data-testid="action-component-add-action-item"
                      >
                        Add Action Item
                      </span>
                    </Button>
                  </div>
                </div>
                {[
                  ActionExecutionType.CREATE,
                  ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
                  ActionExecutionType.UPDATE,
                ].includes(selectedActionType) && (
                  <div className="rounded-b bg-gray-50">
                    {actionItem.data.map((action, index) => (
                      <BaserowActionStepComponent
                        key={index}
                        actionStep={action}
                        onUpdate={(updatedReq) =>
                          handleUpdateBaserowActionItem(updatedReq, index)
                        }
                        nestIndex={index}
                        actionIndex={actionIndex}
                        availableFields={availableFields}
                        triggerTableFields={triggerTableFields}
                      />
                    ))}
                  </div>
                )}
                {[
                  ActionExecutionType.SEND_NOTIFICATION,
                  ActionExecutionType.SEND_EMAIL,
                ].includes(selectedActionType) && (
                  <div className="rounded-b bg-gray-50">
                    {actionItem?.notifyUserData?.map((action, index) => {
                      return (
                        <NotificationActionStepComponent
                          key={index}
                          actionStep={action}
                          onUpdate={(updatedReq) =>
                            handleUpdateNotificationActionItem(
                              updatedReq,
                              index,
                            )
                          }
                          nestIndex={index}
                          availableFields={triggerTableFields}
                          selectedActionType={selectedActionType}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
    </Box>
  )
}

export default ActionComponent
