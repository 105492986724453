import {
  Action,
  RequirementGroupType,
  RequirementType,
} from '@stafflink/zod-types/src/automations'
import { automationFieldTypes } from 'api/src/common/automationFieldTypes'

import { DataValidationResultType } from 'src/components/Automations/AutomationEditForms/AutomationEditFilterForm'

export enum AdvancedLogicOptions {
  FIND_RELATED_RECORDS = 'Find Related Records',
}

export const validateRequirements = (
  requirementsData: RequirementGroupType[],
): DataValidationResultType => {
  if (!requirementsData || requirementsData.length === 0) {
    return { success: true, details: 'No requirements to validate' }
  }

  const validateRequirementOrGroup = (
    requirementOrGroup: RequirementType | RequirementGroupType,
  ): DataValidationResultType => {
    if ('fieldName' in requirementOrGroup) {
      if (!requirementOrGroup.fieldName) {
        return {
          success: false,
          details: 'Requirement field Name is required',
        }
      }
      if (!requirementOrGroup?.fieldType) {
        return {
          success: false,
          details: 'Requirement field Type is required',
        }
      }
      if (!requirementOrGroup?.filter?.value) {
        return { success: false, details: 'Requirement filter is required' }
      }
    } else if ('rule' in requirementOrGroup) {
      if (!requirementOrGroup.rule) {
        return { success: false, details: 'Group rule is required' }
      }
      if (requirementOrGroup?.requirements?.length === 0) {
        return {
          success: false,
          details: 'Conditions are required in the group',
        }
      }
      for (const nestedReqOrGroup of requirementOrGroup.requirements) {
        const nestedValidation = validateRequirementOrGroup(nestedReqOrGroup)
        if (!nestedValidation.success) {
          return nestedValidation
        }
      }
    }
    return { success: true }
  }

  for (const requirementOrGroup of requirementsData) {
    const validation = validateRequirementOrGroup(requirementOrGroup)
    if (!validation.success) {
      return validation
    }
  }

  return { success: true }
}

export const enrichActionItems = (actionObjects: Action[]) => {
  return actionObjects?.map((actionObject) => {
    const enrichedData = actionObject.data.map((actionItem) => {
      const fieldType = automationFieldTypes.find(
        (ft) => ft.value === actionItem.fieldType,
      )
      return {
        fieldName: actionItem?.fieldName,
        fieldId: actionItem?.fieldId,
        fieldType: fieldType?.value,
        inputType: actionItem?.inputType,
      }
    })

    return {
      order: actionObject.order,
      action: actionObject.action,
      outputTableId: actionObject.outputTableId,
      data: enrichedData,
      notifyUserData: actionObject.notifyUserData,
      automationTimezone: actionObject.automationTimezone,
    }
  })
}
