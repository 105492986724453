import { FC } from 'react'

import { CellAutomation } from 'src/components/Automations/AutomationCell/AutomationCell'
import AutomationLogDetailModalCell from 'src/components/Automations/AutomationLogModal/AutomationLogDetailCell/'
import Modal from 'src/components/Modal/Modal'

import { AutomationBaserowData } from '../AutomationLayout'

interface AutomationHistoryModalProps {
  openModal: boolean
  handleModalClose: () => void
  selectedRecordId: number
  selectedAutomation: CellAutomation
  selectedAutomationBaserowData: AutomationBaserowData
}

const AutomationHistoryModal: FC<AutomationHistoryModalProps> = ({
  openModal,
  handleModalClose,
  selectedRecordId,
  selectedAutomation,
  selectedAutomationBaserowData,
}) => {
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      dialogClassName={'w-full max-w-7xl p-0 '}
      childClassName="p-0 max-h-[75vh] min-h-64 overflow-y-scroll"
      title={'Run Details'}
    >
      <AutomationLogDetailModalCell
        id={selectedRecordId}
        automation={selectedAutomation}
        selectedAutomationBaserowData={selectedAutomationBaserowData}
      />
    </Modal>
  )
}
export default AutomationHistoryModal
