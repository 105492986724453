import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/solid'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import {
  Action,
  ActionExecutionType,
  AdvancedLogicFindType,
  AdvancedLogicType,
  FindSelectorType,
  LinkedFieldForFindType,
  MatchCriteriaType,
  RequirementGroupType,
} from '@stafflink/zod-types/src/automations'
import dayjs from 'dayjs'
import {
  BaserowField,
  BaserowWorkspaces,
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
  UpdateAutomation,
  UpdateAutomationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AdvancedLogicForm from 'src/components/Automations/AdvancedLogic/AdvancedLogicForm'
import { enrichActionItems } from 'src/components/Automations/AutomationEditForms/utils'
import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import ActionComponent from '../AutomationActions/ActionComponent'
import { CellAutomation } from '../AutomationCell'
import { AutomationBaserowData } from '../AutomationLayout'
import { SelectOption } from '../AutomationRequirements/AutocompleteComponents'
import { GET_BASEROW_FIELDS, UPDATE_AUTOMATION } from '../queries'
import {
  BaserowObject,
  SelectedLinkFieldRefineValueOption,
  selectedLinkFieldRefineValueOptions,
} from '../utils'

interface AutomationEditActionFormProps {
  selectedAutomation: CellAutomation
  baserowResults: BaserowWorkspaces[]
  selectedAutomationBaserowData: AutomationBaserowData
}

const AutomationEditActionForm: FC<AutomationEditActionFormProps> = ({
  selectedAutomation,
  selectedAutomationBaserowData,
  baserowResults,
}) => {
  // Saving State
  const [automationIsSaving, setAutomationIsSaving] = useState<boolean>(false)

  // Toggle Button for Logic Type
  const [useAdvancedLogic, setUseAdvancedLogic] = useState<boolean>(
    selectedAutomation?.matchCriteria['advancedLogic']?.useAdvancedLogic ??
      false,
  )

  // Filter Requirements
  const [actionList, setActionList] = useState<Action[]>([])
  const [actionsErrorState, setActionsErrorState] = useState<string>(null)
  const [actionsAreInvalid, setActionsAreInvalid] = useState<boolean>(false)
  const [loopingError, setLoopingError] = useState<string>('')

  // Current Information
  const [availableFields, setAvailableFields] = useState<BaserowField[]>([])
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<BaserowObject>(null)
  const [selectedBase, setSelectedBase] = useState<BaserowObject>(null)
  const [selectedTable, setSelectedTable] = useState<BaserowObject>(null)
  const [requirementsInvalid, setRequirementsInvalid] = useState<boolean>(false)
  const [isAutomationLooping, setIsAutomationLooping] = useState<boolean>(false)

  // The list of linked fields attached to the trigger record / trigger table. Used to allow the user to select the field to find the linked records from
  const [linkedTableOptions, setLinkedTableOptions] = useState<
    LinkedFieldForFindType[]
  >([])

  // The selected linked field. Ie, Find all records that are linked via this field
  const [selectedLinkedField, setSelectedLinkedField] =
    useState<LinkedFieldForFindType>(
      (selectedAutomation.matchCriteria as MatchCriteriaType)?.advancedLogic
        ?.FIND?.linkedFieldData,
    )

  // The requirements set when filtering the found linked records by field value
  const [advancedLogicFilterRequirements, setAdvancedLogicFilterRequirements] =
    useState<RequirementGroupType[]>(
      (selectedAutomation.matchCriteria as MatchCriteriaType)?.advancedLogic
        ?.FIND?.filters ?? [],
    )

  // The selected "selector" field. Ie, the selected option that refines all the found linked fields down. For example, last created, first created, etc
  const [selectedLinkFieldRefineValue, setSelectedLinkFieldRefineValue] =
    useState<SelectedLinkFieldRefineValueOption>(
      (selectedAutomation.matchCriteria as MatchCriteriaType)?.advancedLogic
        ?.FIND?.selector
        ? selectedLinkFieldRefineValueOptions.find(
            (option) =>
              option.value ===
              (selectedAutomation.matchCriteria as MatchCriteriaType)
                ?.advancedLogic?.FIND?.selector,
          )
        : null,
    )

  // The selected field that is used to determine the selector. For example, if the selector is first created, this field is the field that holds the created date
  const [
    selectedLinkedFieldForFindingSelector,
    setSelectedLinkedFieldForFindingSelector,
  ] = useState<SelectOption>(
    (selectedAutomation.matchCriteria as MatchCriteriaType)?.advancedLogic?.FIND
      ?.selectorField,
  )

  const baserowTableNotSelected = !selectedAutomationBaserowData?.table?.id
  const confirmToggle = useConfirm()
  const confirmLoopingAutomation = useConfirm()

  const checkIfRefinedValueSelectionsAreValid = () => {
    // If we're not using advanced logic then the input values are irrelevant
    if (!useAdvancedLogic) {
      return true
    }

    // If we are using advanced logic but the selector is not set, then we're invalid
    if (!selectedLinkFieldRefineValue) {
      return false
    }

    // If we are using advanced logic and the selector is set to all records, then we're valid
    if (selectedLinkFieldRefineValue.value === FindSelectorType.ALL_RECORDS) {
      return true
    }

    // If we are using advanced logic and the selector is set to a specific record, then we need to check the selector field
    // If the selector field is set, then we're valid
    if (selectedLinkedFieldForFindingSelector) {
      return true
    }

    // Here means that the field is not selected for a specific record
    return false
  }

  const handleAdvanceLogicToggle = (
    _event: React.MouseEvent<HTMLElement>,
    filterView: boolean,
  ) => {
    // Only confirm if some data exists
    if (actionList?.length > 0) {
      confirmToggle({
        title: 'Toggle Advanced Logic',
        description:
          'Toggling  advanced logic creates a conflict in existing actions. Your actions will be cleared.',
      }).then((isConfirmed) => {
        if (!isConfirmed) return
        filterView !== null && setUseAdvancedLogic(filterView)
        updateactionList([])
      })
    } else {
      filterView !== null && setUseAdvancedLogic(filterView)
    }

    // If toggled advanced logic OFF
    if (!filterView) {
      // clear what state there is
      setSelectedLinkedField(null)
      setSelectedLinkFieldRefineValue(null)
    }
  }

  const [GetTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      return data.getBaserowTableFields
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
      return []
    },
  })

  const [updateAutomation] = useMutation<
    UpdateAutomation,
    UpdateAutomationVariables
  >(UPDATE_AUTOMATION, {
    onCompleted: () => {
      setAutomationIsSaving(false)
      toast.success('Automation Updated')
    },
    onError: () => {
      setAutomationIsSaving(false)
      toast.error('There was a problem updating your Automation')
    },
    refetchQueries: ['FindAutomationDashboardQuery', 'GetAutomationDetails'],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const updateactionList = (updatedactionList: Action[]) => {
    setActionList(updatedactionList)
  }

  const addAction = () => {
    const outputTableId = useAdvancedLogic
      ? (selectedLinkedField?.linkedToTable ?? null)
      : null

    const newAction = {
      notifyUserData: [],
      data: [],
      action: ActionExecutionType.CREATE,
      order: Math.max(...actionList.map((action) => action.order), 0) + 1,
      outputTableId,
      automationTimezone: null,
    }
    setActionList([...actionList, newAction])
    setActionsErrorState(null)
  }

  const setInitialActionItem = () => {
    let actionObjects: Action[] = []
    actionObjects = selectedAutomation.action as Action[]

    const actionList = enrichActionItems(actionObjects)

    setActionList(actionList)
  }

  const setInitialAvailableFields = () => {
    const getTableFields = async (tableId, workspaceId) => {
      const { data } = await GetTableFields({
        variables: {
          input: {
            tableId,
            workspaceId,
          },
        },
      })
      return data
    }

    // If we have a set table and workspace - find the table fields
    if (selectedTable?.value && selectedWorkspace?.value) {
      getTableFields(
        selectedTable.value?.toString(),
        selectedWorkspace.value?.toString(),
      ).then((data) => {
        // Set Field Options
        setAvailableFields(data.getBaserowTableFields)

        // Set Linked Field options
        setLinkedTableOptions(
          data.getBaserowTableFields
            .filter((field) => field.type === 'link_row')
            .map(
              (field) =>
                ({
                  value: field.id,
                  label: field.name,
                  linkedToTable: field.link_row_table_id,
                  type: field.type,
                }) as LinkedFieldForFindType,
            )
            .sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
            ),
        )
      })
    }
  }

  const validateActionList = () => {
    // Keep in sync with automations/utils::checkAutomationActionRequirements()
    // Check if actions is an array
    if (!Array.isArray(actionList)) {
      return {
        success: false,
        details: 'Automation must have actions to perform.',
      }
    }

    // Check if actions is empty
    if (actionList.length === 0) {
      return {
        success: false,
        details: 'Automation must have actions to perform.',
      }
    }

    // For each action now that we know it is a list of more than 0 items
    for (const actionStep of actionList) {
      // If the action is null
      if (!actionStep.action) {
        return {
          success: false,
          details: 'Action is required',
        }
      }

      // If the action applies to a baserow record
      if (
        [
          ActionExecutionType.CREATE,
          ActionExecutionType.UPDATE,
          ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
        ].includes(actionStep.action)
      ) {
        // The action needs an output table ID
        if (!actionStep.outputTableId) {
          return {
            success: false,
            details: 'Output Table Id is required',
          }
        }

        // Each action has to have action item(s) to perform
        if (actionStep.data?.length === 0) {
          return {
            success: false,
            details: 'Action is required',
          }
        }

        // For each action item in the action
        for (const actionItem of actionStep.data) {
          // The action needs a field name to action against
          if (!actionItem?.fieldName) {
            return {
              success: false,
              details: 'Action field Name is required',
            }
          }

          // An action item must have a fieldId
          if (!actionItem?.fieldId) {
            return {
              success: false,
              details: 'Action field Id is required',
            }
          }

          // An action item must have a fieldType
          if (!actionItem?.fieldType) {
            return {
              success: false,
              details: 'Action field Type is required',
            }
          }

          if (!actionItem?.inputType) {
            return {
              success: false,
              details: 'Action input Type is required',
            }
          }

          // Linked Row does not always require params - so we can skip
          if (actionItem?.fieldType !== 'link_row') {
            if (actionItem?.inputType?.params?.['1']) {
              if (!actionItem?.inputType?.params['1']?.value) {
                return {
                  success: false,
                  details: 'Action input option is required',
                }
              }

              if (
                actionItem?.inputType?.params['2']?.dependency?.conditions?.[
                  actionItem?.inputType?.params['1']?.value
                ]
              ) {
                if (!actionItem?.inputType?.params['2']?.value) {
                  return {
                    success: false,
                    details: 'Action input option value is required',
                  }
                }
              }
            } else {
              // We don't need an action option if it's a clear action, otherwise we do
              if (actionItem?.inputType?.value !== 'clear') {
                return {
                  success: false,
                  details: 'Action option is required',
                }
              }
            }
          } else {
            // But we need to check a value is set for Linked_Row
            if (!actionItem?.inputType?.value) {
              return {
                success: false,
                details: 'Action input option is required',
              }
            }
          }
        }
      } else if (
        [
          ActionExecutionType.SEND_NOTIFICATION,
          ActionExecutionType.SEND_EMAIL,
        ].includes(actionStep.action)
      ) {
        // If there are no notifications to send
        if (
          !actionStep?.notifyUserData ||
          actionStep.notifyUserData?.length === 0
        ) {
          return {
            success: false,
            details: 'Action is required',
          }
        }

        for (const actionItem of actionStep.notifyUserData) {
          if (!actionItem?.notificationMessage) {
            return {
              success: false,
              details: 'Notification message is required',
            }
          }
          if (!actionItem?.notificationTitle) {
            return {
              success: false,
              details: 'Notification title is required',
            }
          }
          if (!actionItem?.matchingData) {
            return {
              success: false,
              details: 'User source is required',
            }
          }
          if (!actionItem?.matchingData.matchOn) {
            return {
              success: false,
              details: 'User source is required',
            }
          }
          if (!actionItem?.matchingData.matchType) {
            return {
              success: false,
              details: 'User source type is required',
            }
          }
          if (actionItem?.matchingData.matchValues.length === 0) {
            return {
              success: false,
              details: 'Users have not been specified',
            }
          }
        }
      } else {
        // Should never get here
        return {
          success: false,
          details: 'Action is not supported',
        }
      }
    }
    return {
      success: true,
    }
  }

  const handleSaveDetails = async () => {
    setAutomationIsSaving(true)
    setActionsErrorState(null)

    // If it's potentially looping, confirm dialog
    if (isAutomationLooping) {
      const confirmResult = await confirmLoopingAutomation({
        title: 'Automation Looping',
        description: (
          <>
            {loopingError}
            <br />
            <br />
            Are you sure you want to continue?
          </>
        ),
      })

      if (!confirmResult) {
        setAutomationIsSaving(false)
        return
      }
    }

    // Validate the input
    const validate = validateActionList()

    if (validate.success) {
      const matchCriteria: MatchCriteriaType = {
        FIELD: {
          ...selectedAutomation.matchCriteria['FIELD'],
        },
        RECORD: {
          ...selectedAutomation.matchCriteria['RECORD'],
        },
        advancedLogic: {
          useAdvancedLogic,
          advancedLogicType: useAdvancedLogic ? AdvancedLogicType.FIND : null, // hard set because we have no other options
          FIND: useAdvancedLogic
            ? {
                linkedFieldData: selectedLinkedField,
                TYPE: 'LINKED_FIELD' as AdvancedLogicFindType,
                selector: selectedLinkFieldRefineValue?.value,
                selectorField: selectedLinkedFieldForFindingSelector,
                filters: advancedLogicFilterRequirements,
              }
            : null,
        },
      }

      updateAutomation({
        variables: {
          id: selectedAutomation.id,
          inputData: {
            action: JSON.parse(JSON.stringify(actionList)),
            matchCriteria,
            automationTimeZone: dayjs.tz.guess(),
          },
          baserowData: null,
        },
      })
    } else {
      setAutomationIsSaving(true)
      setActionsErrorState(
        'There are missing inputs in your actions. Please fix these and try again.',
      )
    }
  }

  useEffect(() => {
    setSelectedBase({
      value: selectedAutomationBaserowData?.base?.id,
      label: selectedAutomationBaserowData?.base?.name,
    })
    setSelectedTable({
      value: selectedAutomationBaserowData?.table?.id,
      label: selectedAutomationBaserowData?.table?.name,
    })
    setSelectedWorkspace({
      value: selectedAutomationBaserowData?.workspace?.id,
      label: selectedAutomationBaserowData?.workspace?.name,
    })
  }, [selectedAutomation])

  useEffect(() => {
    // Set all our table options when the selected table changes
    setInitialActionItem()
    setInitialAvailableFields()
  }, [selectedTable])

  useEffect(() => {
    const actionsValidated = validateActionList()

    if (actionsValidated.success) {
      setActionsAreInvalid(false)
    } else {
      setActionsAreInvalid(true)
    }
  }, [actionList])

  return (
    <div className="flex h-full grow flex-col">
      <div className="w-full p-6 px-4">
        {baserowTableNotSelected && (
          <div className="mb-4 flex items-start justify-between gap-6 rounded border border-amber-600 bg-amber-100 p-4 text-amber-600">
            Actions cannot be set until a Trigger Table is selected.
          </div>
        )}
        <div className="mb-4 flex items-start justify-between gap-6">
          <div>
            <p className="mb-2 text-lg">Advanced Logic</p>
            <p className="text-sm font-light text-gray-400">
              Modify rows with matching linked field.
            </p>
          </div>
          <ToggleButtonGroup
            color="primary"
            value={useAdvancedLogic}
            exclusive
            onChange={handleAdvanceLogicToggle}
          >
            <ToggleButton
              disableRipple
              className={`w-28 gap-3 border-gray-400 py-1 text-gray-300 ${
                useAdvancedLogic &&
                'border-indigo-600 !bg-indigo-100 !text-indigo-600'
              }`}
              value={true}
            >
              <CheckCircleIcon className="h-5 w-5" />
              <span className="pt-0.5">On</span>
            </ToggleButton>
            <ToggleButton
              disableRipple
              className={`ml-0 w-28 gap-3 border-gray-400 py-1 text-gray-300 ${
                !useAdvancedLogic &&
                'border-indigo-600 !border-l-indigo-600 !bg-indigo-100 !text-indigo-600'
              }`}
              value={false}
            >
              <CheckCircleIcon className="h-5 w-5" />
              <span className="pt-0.5">Off</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {useAdvancedLogic && (
          <AdvancedLogicForm
            setRequirementsInvalid={setRequirementsInvalid}
            selectedWorkspace={selectedWorkspace}
            linkedTableOptions={linkedTableOptions}
            setSelectedLinkedFieldForFindingSelector={
              setSelectedLinkedFieldForFindingSelector
            }
            selectedLinkedFieldForFindingSelector={
              selectedLinkedFieldForFindingSelector
            }
            setAdvancedLogicFilterRequirements={
              setAdvancedLogicFilterRequirements
            }
            advancedLogicFilterRequirements={advancedLogicFilterRequirements}
            setSelectedLinkFieldRefineValue={setSelectedLinkFieldRefineValue}
            selectedLinkFieldRefineValue={selectedLinkFieldRefineValue}
            setSelectedLinkedField={setSelectedLinkedField}
            selectedLinkedField={selectedLinkedField}
            selectedAutomationBaserowData={selectedAutomationBaserowData}
          />
        )}

        <div className="flex items-start justify-between gap-4">
          <div>
            <p className="mb-2 text-lg">Actions</p>
            <p className="text-sm font-light text-gray-400">
              {actionList?.length === 0 ? 'None set' : 'Edit actions'}
            </p>
          </div>
          <Button
            disabled={baserowTableNotSelected}
            fullWidth={false}
            variant="outlined"
            className="min-w-[0] hover:bg-indigo-100"
            onClick={() => {
              addAction()
            }}
            startIcon={<PlusIcon className="h-5 w-5" />}
            buttonDataTestId="action-component-add-action-step"
          >
            <span className="pt-0.5">Add Action Step</span>
          </Button>
        </div>
        <div className="flex w-full flex-col items-start justify-between gap-4">
          {actionList?.map((action, index) => (
            <ActionComponent
              key={index}
              workspacesAndBases={baserowResults}
              actionItem={action}
              actionIndex={index}
              GetTableFields={GetTableFields}
              loadingTableFields={loadingTableFields}
              onUpdate={(updatedReq) => {
                const newData = [...actionList]
                if (updatedReq === null) {
                  newData.splice(index, 1)
                } else {
                  newData[index] = updatedReq
                }
                updateactionList(newData)
              }}
              triggerTableFields={availableFields}
              triggerWorkspace={selectedWorkspace}
              triggerBase={selectedBase}
              triggerTable={selectedTable}
              useAdvancedLogic={useAdvancedLogic}
              advancedLogicTableId={selectedLinkedField?.linkedToTable ?? null}
              selectedAutomation={selectedAutomation}
              setIsAutomationLooping={setIsAutomationLooping}
              isAutomationLooping={isAutomationLooping}
              loopingError={loopingError}
              setLoopingError={setLoopingError}
            />
          ))}
        </div>
      </div>
      {actionList?.length > 0 && (
        <div className="sticky bottom-0 z-[3] flex w-full flex-row items-center justify-between gap-4 border-t border-gray-300 bg-white p-6">
          <Button
            fullWidth={false}
            onClick={handleSaveDetails}
            loading={automationIsSaving}
            disabled={
              actionsAreInvalid ||
              baserowTableNotSelected ||
              !checkIfRefinedValueSelectionsAreValid() ||
              requirementsInvalid
            }
          >
            Save
          </Button>
          <p className="mr-20 text-sm text-red-500">{actionsErrorState}</p>
        </div>
      )}
    </div>
  )
}

export default AutomationEditActionForm
