import React, { FC } from 'react'

import Stack from '@mui/material/Stack'
import {
  AutomationLogCriteriaResultItemType,
  AutomationLogCriteriaResultsType,
} from '@stafflink/zod-types/src/automations'

import DetailValue from 'src/components/Automations/AutomationLogModal/Components/DetailView'
import Accordion from 'src/components/Library/Accordion'

interface BaserowRecordFieldDifferentialListProps {
  data: AutomationLogCriteriaResultsType
}

type MismatchedDataType = {
  fieldName: string
  oldValue: string
  newValue: string
  oldSuccess: boolean
  newSuccess: boolean
}

const BaserowRecordFieldDifferentialList: FC<
  BaserowRecordFieldDifferentialListProps
> = ({ data }): JSX.Element => {
  const findMismatchedResults = (
    oldData: AutomationLogCriteriaResultItemType,
    newData: AutomationLogCriteriaResultItemType,
  ): MismatchedDataType[] => {
    const mismatches = []

    const compareNestedResults = (
      oldResults: Record<string, any>[],
      newResults: Record<string, any>[],
    ) => {
      if (!oldResults || !newResults) return

      for (let index = 0; index < oldResults.length; index++) {
        const oldResult: Record<string, any> = oldResults[index]
        const newResult: Record<string, any> = newResults[index]

        if (
          oldResult?.fieldName &&
          newResult?.fieldName &&
          oldResult?.success !== newResult?.success
        ) {
          mismatches.push({
            fieldName:
              oldResult?.fieldName?.name ?? oldResult?.fieldName ?? '-',
            oldValue: oldResult?.fieldValue,
            newValue: newResult?.fieldValue,
            oldSuccess: oldResult?.success,
            newSuccess: newResult?.success,
          })
        }

        if (oldResult?.results && newResult?.results) {
          compareNestedResults(oldResult?.results, newResult?.results)
        }
      }
    }

    compareNestedResults(oldData.results, newData.results)
    return mismatches
  }

  const mismatchedResults = findMismatchedResults(data.oldData, data.newData)

  if (mismatchedResults.length === 0) {
    return null
  }

  return (
    <Accordion
      removeDetailsPadding
      title={
        <p className="font-light">{`Field${
          mismatchedResults.length > 1 ? 's' : ''
        } That Triggered Automation`}</p>
      }
      customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
      borderstyle="!border-0 before:hidden !rounded-0 !overflow-hidden"
    >
      <div className="bg-gray-50 p-4">
        <Stack direction={'row'} spacing={2} className="mb-2 border-b pb-1">
          <DetailValue setWidth>
            <b>Field</b>
          </DetailValue>
          <DetailValue setWidth>
            <b>Old Value</b>
          </DetailValue>
          <DetailValue setWidth>
            <b>New Value</b>
          </DetailValue>
        </Stack>

        {mismatchedResults.map((result: MismatchedDataType, index) => {
          // Conditionally render different content based on the condition
          return result.fieldName === 'None' && result.oldValue === 'None' ? (
            <Stack direction={'row'} spacing={2} key={index}>
              <DetailValue setWidth>No Field - New Record</DetailValue>
              <DetailValue setWidth>-</DetailValue>
              <DetailValue setWidth>-</DetailValue>
            </Stack>
          ) : (
            <Stack direction={'row'} spacing={2} key={index}>
              <DetailValue setWidth>{result?.fieldName ?? '-'}</DetailValue>
              <DetailValue setWidth>{result?.oldValue || '-'}</DetailValue>
              <DetailValue setWidth>{result?.newValue || '-'}</DetailValue>
            </Stack>
          )
        })}
      </div>
    </Accordion>
  )
}

export default BaserowRecordFieldDifferentialList
