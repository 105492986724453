import {
  Action,
  AutomationLogActionItemType,
} from '@stafflink/zod-types/src/automations'
import { AutomationLogStatus, BaserowField } from 'types/graphql'

import { AutomationLogType } from 'src/components/Automations/AutomationLogModal/AutomationLogDetailCell'

export type InformationItemType = {
  key: string
  value: string
}

export const eventTypeTranslator = (eventType: string): string => {
  switch (eventType) {
    case 'rows.created':
      return 'Row Created'
    case 'rows.updated':
      return 'Row Updated'
    case 'rows.deleted':
      return 'Row Deleted'
    default:
      return eventType
  }
}

interface GetTableFieldNameProps {
  fieldIdFull: string
  automationLogRelatedAutomationAction: Action[]
  baserowTableFields: BaserowField[]
}

export const getTableFieldName = ({
  fieldIdFull,
  automationLogRelatedAutomationAction,
  baserowTableFields,
}: GetTableFieldNameProps): string => {
  // Build the field name translator from the automation actions
  const fieldNameTranslator = automationLogRelatedAutomationAction.reduce(
    (acc, actionItem) => {
      if (actionItem?.data) {
        for (const dataItem of actionItem.data) {
          acc[`field_${dataItem.fieldId}`] = dataItem.fieldName
        }
      }
      return acc
    },
    {} as Record<string, string>,
  )

  // Check if the fieldIdFull exists in the translator
  if (fieldIdFull in fieldNameTranslator) {
    return fieldNameTranslator[fieldIdFull]
  }

  // Handle cases where the fieldIdFull starts with 'field_'
  if (fieldIdFull.startsWith('field_')) {
    const fieldId = fieldIdFull.slice('field_'.length)

    for (const field of baserowTableFields) {
      if (field.id.toString() === fieldId) {
        return field.name
      }
    }

    return 'Unknown Field'
  }

  // Default case: return the fieldIdFull itself
  return fieldIdFull
}

export const getLogStatusColor = (status: AutomationLogStatus) => {
  switch (status) {
    case 'COMPLETED':
      return 'bg-emerald-100 text-emerald-500'
    case 'FAILED':
      return 'bg-rose-100 text-rose-500'
    case 'RUNNING':
      return 'bg-cyan-100 text-cyan-500'
    case 'PARTIAL':
      return 'bg-amber-100 text-amber-500'
    default:
      return 'gray'
  }
}

export const calculateActionLogStatus = (
  automationLogRecord: AutomationLogType,
) => {
  if (!automationLogRecord) {
    return 'FAILED'
  }

  const automationLogActionData =
    automationLogRecord?.actionData as AutomationLogActionItemType[]

  if (automationLogRecord.status === 'COMPLETED') {
    return 'COMPLETED'
  }

  const totalActions = automationLogActionData.length
  const failedActions = automationLogActionData.filter(
    (action) => !action.actionSuccess,
  ).length

  if (failedActions > 0 && failedActions < totalActions) {
    return 'PARTIAL'
  }

  return 'FAILED'
}
