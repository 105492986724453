import { FC } from 'react'

import {
  Action,
  ActionExecutionType,
  AdvancedLogicObject,
} from '@stafflink/zod-types/src/automations'

import AutomationTrackActionItemBaserow from 'src/components/Automations/AutomationTrack/AutomationTrackActionItemBaserow'
import AutomationTrackActionItemNotification from 'src/components/Automations/AutomationTrack/AutomationTrackActionNotifications'

interface AutomationTrackActionProps {
  action: Action
  actionIndex: number
  advancedLogic: AdvancedLogicObject
  onActionClick: () => void
}

const AutomationTrackAction: FC<AutomationTrackActionProps> = ({
  action,
  actionIndex,
  advancedLogic,
  onActionClick = () => {},
}) => {
  const useAdvancedLogic = advancedLogic?.useAdvancedLogic ?? false

  const actionType = action.action as ActionExecutionType

  return (
    <div
      className="relative w-full rounded border border-gray-300 bg-gray-50 p-4 px-3"
      role="button"
      onClick={onActionClick}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <p className="capitalize">
        Action {actionIndex}:&nbsp;
        <span className="capitalize text-gray-500">
          {useAdvancedLogic &&
            `Update record in table ${advancedLogic['FIND']?.linkedFieldData?.linkedToTable} that match ${advancedLogic['FIND']?.linkedFieldData?.label}`}
          {!useAdvancedLogic &&
            ` ${action.action.split('_').join(' ').toLowerCase()} Event `}
        </span>
      </p>
      <div className="mt-3 flex flex-col gap-3">
        {[
          ActionExecutionType.UPDATE,
          ActionExecutionType.CREATE,
          ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
        ].includes(actionType) &&
          action.data.map((action, index) => {
            return (
              <AutomationTrackActionItemBaserow key={index} action={action} />
            )
          })}

        {[
          ActionExecutionType.SEND_NOTIFICATION,
          ActionExecutionType.SEND_EMAIL,
        ].includes(actionType) &&
          action.notifyUserData.map((action, index) => {
            return (
              <AutomationTrackActionItemNotification
                key={index}
                action={action}
                actionType={actionType}
              />
            )
          })}
      </div>
    </div>
  )
}

export default AutomationTrackAction
