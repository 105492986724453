import React, { FC } from 'react'

import { Chip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { AutomationLogStatus } from 'types/graphql'

import RowDetail from 'src/components/Automations/AutomationLogModal/Components/RowDetail'

import { getLogStatusColor, InformationItemType } from '../utils'

import DetailTitle from './DetailTitle'

interface RowDetailsProps {
  status: AutomationLogStatus
  informationItems: InformationItemType[]
}

const RowDetails: FC<RowDetailsProps> = ({ status, informationItems }) => {
  return (
    <div className="flex flex-wrap gap-6">
      {informationItems.map((item) => (
        <RowDetail key={item.key} informationItem={item} />
      ))}

      <Stack
        alignContent={'center'}
        justifyContent={'center'}
        className="flex items-center"
      >
        <DetailTitle>Outcome</DetailTitle>
        <Chip
          size="small"
          className={`rounded px-2 text-sm ${getLogStatusColor(status)}`}
          label={status}
        />
      </Stack>
    </div>
  )
}

export default RowDetails
