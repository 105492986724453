import { FC, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import { UpdateAutomation, UpdateAutomationVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import IconButton from 'src/components/Library/IconButton'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import { UPDATE_AUTOMATION } from '../queries'

export enum ClearType {
  FILTER = 'Filters',
  ACTION = 'Actions',
}

interface ClearAutomationInputButtonProps {
  clearItem: ClearType
  automationId: number
  disabled: boolean
}

const ClearAutomationInputButton: FC<ClearAutomationInputButtonProps> = ({
  clearItem,
  automationId,
  disabled,
}) => {
  const [isClearing, setIsClearing] = useState<boolean>(false)

  const confirm = useConfirm()

  const [updateAutomation] = useMutation<
    UpdateAutomation,
    UpdateAutomationVariables
  >(UPDATE_AUTOMATION, {
    onCompleted: () => {
      setIsClearing(false)
      toast.success(`Automation ${clearItem} Cleared`)
    },
    onError: () => {
      setIsClearing(false)
      toast.error('There was a problem updating your Automation')
    },
    refetchQueries: ['FindAutomationDashboardQuery', 'GetAutomationDetails'],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const handleClearAutomationDetails = () => {
    setIsClearing(true)

    if (clearItem === ClearType.ACTION) {
      updateAutomation({
        variables: {
          id: automationId,
          inputData: {
            action: [],
            active: false,
          },
          baserowData: null,
        },
      })
    }

    if (clearItem === ClearType.FILTER) {
      updateAutomation({
        variables: {
          id: automationId,
          inputData: {
            matchCriteria: JSON.parse(JSON.stringify({})),
          },
          baserowData: null,
        },
      })
    }
    setIsClearing(false)
  }

  return (
    <Tooltip
      title={!disabled && `Clear all ${clearItem} `}
      arrow
      placement="top"
    >
      <div>
        <IconButton
          // TODO Sam this is throwing an error to console console.js:288 Warning: Received `false` for a non-boolean attribute `loading`.
          // loading={isClearing}
          disabled={disabled || isClearing}
          onClick={() => {
            confirm({
              title: 'Clear ' + clearItem,
              description:
                clearItem === ClearType.ACTION
                  ? 'Clearing actions will prevent the automation from making changes. Are you sure you want to do this?'
                  : 'Clearing the filters will make the automation fire on every event in the table. Are you sure you want to do this?',
              confirmationText: 'Clear ' + clearItem,
            }).then((isConfirmed) => {
              if (!isConfirmed) return
              handleClearAutomationDetails()
            })
          }}
        >
          <TrashIcon
            className={`h-5 w-5 ${disabled ? 'text-gray-300' : 'text-gray-400'}`}
          />
        </IconButton>
      </div>
    </Tooltip>
  )
}

export default ClearAutomationInputButton
