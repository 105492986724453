import { FC } from 'react'

import Stack from '@mui/material/Stack'
import {
  ActionExecutionType,
  AutomationsMemberNotificationMatchOnType,
  AutomationsMemberNotificationMatchType,
  AutomationUserNotificationDataType,
} from '@stafflink/zod-types/src/automations'

import { ValueChip, ValueChipTheme } from './AutomationTrackValueChip'

interface AutomationTrackActionItemNotificationProps {
  action: AutomationUserNotificationDataType
  actionType: ActionExecutionType
}

const AutomationTrackActionItemNotification: FC<
  AutomationTrackActionItemNotificationProps
> = ({ action, actionType }) => {
  const convertAutomationsUserNotificationMatchTypeToHumanReadable = (
    value: AutomationsMemberNotificationMatchType,
  ) => {
    switch (value) {
      case AutomationsMemberNotificationMatchType.EMAIL:
        return 'Emails'
      case AutomationsMemberNotificationMatchType.FULL_NAME:
        return 'Full Names'
      case AutomationsMemberNotificationMatchType.MEMBERSHIP_ID:
        return 'Membership Ids'
      case AutomationsMemberNotificationMatchType.BASEROW_COLLABORATOR:
        return 'Membership'
    }
  }
  return (
    <div className="rounded border border-gray-300 bg-white px-3 py-4">
      <p className="items-center gap-2 text-gray-600">
        {action?.matchingData.matchOn ===
          AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS && (
          <Stack direction="row" spacing={1} alignItems={'start'}>
            <span className="mr-2 pt-1 font-light">{`Send ${actionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification' : 'email'} to`}</span>
            <div className="flex w-2/3 flex-wrap gap-2">
              {action?.matchingData?.matchValues.map((matchValue, index) => (
                <ValueChip
                  key={index}
                  value={matchValue.label}
                  theme={ValueChipTheme.GREEN}
                />
              ))}
            </div>
          </Stack>
        )}
        {action?.matchingData.matchOn ===
          AutomationsMemberNotificationMatchOnType.WORKFLOW_FIELD_VALUE && (
          <>
            <span className="mr-2 font-light">{`Send ${actionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification' : 'email'} to`}</span>
            <ValueChip value={'Custom List'} theme={ValueChipTheme.BLUE} />
            <span className="mr-2 font-light">based on</span>
            <ValueChip
              value={convertAutomationsUserNotificationMatchTypeToHumanReadable(
                action?.matchingData.matchType,
              )}
              theme={ValueChipTheme.GREEN}
            />
            <span className="mr-2 font-light">found in the</span>
            <ValueChip
              value={action?.matchingData?.matchValues[0]?.label}
              theme={ValueChipTheme.VIOLET}
            />
            <span className="mr-2 font-light">workflow field </span>
          </>
        )}
      </p>
    </div>
  )
}

export default AutomationTrackActionItemNotification
