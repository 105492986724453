import React, { FC } from 'react'

import Stack from '@mui/material/Stack'

import { InformationItemType } from '../utils'

import DetailTitle from './DetailTitle'
import DetailValue from './DetailView'

interface RowDetailProps {
  informationItem: InformationItemType
}

const RowDetail: FC<RowDetailProps> = ({ informationItem }) => {
  return (
    <Stack
      alignContent={'center'}
      justifyContent={'center'}
      className="flex items-center"
    >
      <DetailTitle>{informationItem.key}</DetailTitle>
      <DetailValue>{informationItem.value}</DetailValue>
    </Stack>
  )
}

export default RowDetail
