import React, { FC } from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'

interface SectionTitleProps {
  sectionName: string
  sectionTooltip?: string
}

const SectionTitle: FC<SectionTitleProps> = ({
  sectionName,
  sectionTooltip,
}) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Typography>{sectionName}</Typography>
      {sectionTooltip && (
        <Tooltip title={sectionTooltip}>
          <div>
            <InformationCircleIcon className="h-5 w-5 text-blue-500" />
          </div>
        </Tooltip>
      )}
    </Stack>
  )
}

export default SectionTitle
