import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import {
  DeleteAutomationCategory,
  DeleteAutomationCategoryVariables,
  UpdateAutomationCategoryRank,
  UpdateAutomationCategoryRankVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import DragAndDropFolderList from 'src/components/Library/DragAndDropFolders/DragAndDropFolderList'
import DraggableFolder from 'src/components/Library/DragAndDropFolders/DraggableFolder'
import { default as EmptyData } from 'src/components/Library/Empty'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { useAuth } from 'src/Providers'

import { CellAutomation, CellAutomationCategory } from '../AutomationCell'
import {
  DELETE_AUTOMATION_CATEGORY,
  UPDATE_AUTOMATION_CATEGORY_RANK,
} from '../queries'

import AutomationListItem from './AutomationListItem'
import FolderTitleActionBar from './FolderTitleActionBar'

interface AutomationFolderListProps {
  isSubFolder?: boolean
  grid?: number
  automationCategories: CellAutomationCategory[]
  uncategorisedAutomations?: CellAutomationCategory[]
  parentFolderList?: CellAutomationCategory[]
  setParentFolderList?: (param: CellAutomationCategory[]) => void
  droppableId?: string
  handleEditCategorySelection: (id: number) => void
  handleCreateAutomationModalOpen: (categoryId: number) => void
  disableDragAndDrop?: boolean
  selectedAutomation: CellAutomation
  setSelectedAutomation: Dispatch<SetStateAction<CellAutomation>>
}

const AutomationFolderList: FC<AutomationFolderListProps> = ({
  isSubFolder = false,
  grid = 0,
  automationCategories,
  uncategorisedAutomations = [],
  parentFolderList = [],
  setParentFolderList = () => {},
  droppableId = 'parentDroppableId',
  handleEditCategorySelection,
  disableDragAndDrop = false,
  selectedAutomation,
  setSelectedAutomation,
  handleCreateAutomationModalOpen,
}) => {
  const { currentUser } = useAuth()

  const [folderList, setFolderList] =
    useState<CellAutomationCategory[]>(automationCategories)

  const [rankAutomationCategories] = useMutation<
    UpdateAutomationCategoryRank,
    UpdateAutomationCategoryRankVariables
  >(UPDATE_AUTOMATION_CATEGORY_RANK, {
    onCompleted: () => {
      toast.success('Order Saved')
    },
  })

  const [archiveAutomationCategory] = useMutation<
    DeleteAutomationCategory,
    DeleteAutomationCategoryVariables
  >(DELETE_AUTOMATION_CATEGORY, {
    onCompleted: () => {
      toast.success('Automation Category Deleted')
    },
    onError: () => {
      toast.error('There was a problem deleting your category.')
    },
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const confirm = useConfirm()

  const handleCategoryArchive = (id: number) => {
    confirm({
      title: 'Delete Category',
      description:
        'Are you sure you want to delete this category? Any automations and sub categories will also be deleted.',
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error', className: 'bg-red-500' },
    }).then(async (isConfirmed) => {
      if (!isConfirmed) return
      await archiveAutomationCategory({
        variables: {
          id,
        },
      })
    })
  }

  const handleAutomationRanking = async (reorderedItems) => {
    const reorderedItemsInput = reorderedItems.map((item, index) => ({
      id: item.id,
      rank: index + 1,
    }))

    await rankAutomationCategories({
      variables: {
        input: { itemType: 'AutomationCategory', items: reorderedItemsInput },
      },
    })
  }

  const onDragEnd = (reorderedItems) => {
    // Parent and Child State Management

    // We call this component inside itself
    // The parent component default state comes from the Cell
    // The child component default state comes from the Parent Component
    // When you reorder children, then reorder parents - you will lose the child order because we are not refetching the saved order.
    // To avoid this when we reorder children - we update the parent level state with the new children order.

    // e.g. setParentState( [...existingParentItems, parentFolder: { subFolders: reorderedList }] )

    if (isSubFolder) {
      // This is a child folder

      const parentFolderId = reorderedItems[0]?.parentCategoryId

      const newParentItems = parentFolderList.map((category) => {
        let childCategories = category.childCategories

        if (category.id === parentFolderId) {
          childCategories = reorderedItems
        }

        return { ...category, childCategories }
      })

      // Update the child state
      setFolderList(reorderedItems)

      // Update the top level state
      setParentFolderList(newParentItems)
    } else {
      // This is a parent folder

      // Set the top level items
      setFolderList(reorderedItems)
    }

    // Save the reorder to the db
    // Fire and Forget
    handleAutomationRanking(reorderedItems)
  }

  // Update state on the refetch
  useEffect(() => {
    setFolderList(automationCategories)
  }, [automationCategories])

  return (
    <>
      {folderList?.length === 0 && uncategorisedAutomations?.length === 0 && (
        <div className="grid grow place-items-center">
          <EmptyData title="No Automations Found" />
        </div>
      )}

      {/* We get uncategorised automations when existing automations aren't updated to be in a category*/}

      {(folderList?.length > 0 || uncategorisedAutomations?.length > 0) && (
        <DragAndDropFolderList
          folders={folderList}
          onDragEnd={onDragEnd}
          droppableId={droppableId}
        >
          {uncategorisedAutomations?.length > 0 && (
            <>
              {uncategorisedAutomations.map((uncategorisedFolder) => (
                <DraggableFolder
                  grid={grid}
                  key={uncategorisedFolder?.name + uncategorisedFolder?.id}
                  folder={uncategorisedFolder}
                  index={uncategorisedFolder?.id}
                  isSubFolder={false}
                  disableDragAndDrop={true}
                >
                  <div className="bg-white">
                    {uncategorisedFolder?.automations?.map(
                      (automation, index) => (
                        <AutomationListItem
                          selected={selectedAutomation?.id === automation?.id}
                          setSelectedAutomation={setSelectedAutomation}
                          automation={automation as CellAutomation}
                          key={index}
                        />
                      ),
                    )}
                  </div>
                </DraggableFolder>
              ))}
            </>
          )}

          {folderList.map((folder, index) => {
            return (
              <div key={index}>
                <DraggableFolder
                  grid={grid}
                  key={index}
                  folder={folder}
                  index={index}
                  isSubFolder={isSubFolder}
                  disableDragAndDrop={disableDragAndDrop}
                  FolderTitleActions={
                    <FolderTitleActionBar
                      id={folder.id}
                      name={folder.name}
                      onClick={handleEditCategorySelection}
                      onArchive={handleCategoryArchive}
                    />
                  }
                >
                  <div className="bg-white">
                    {folder?.childCategories?.length > 0 && (
                      <div
                        style={{
                          minHeight: folder.childCategories?.length * 52 + 'px',
                        }}
                      >
                        <AutomationFolderList
                          droppableId={'subFolderDroppableId' + folder.id}
                          parentFolderList={folderList}
                          setParentFolderList={setFolderList}
                          grid={0}
                          isSubFolder
                          automationCategories={
                            folder.childCategories as CellAutomationCategory[]
                          }
                          handleEditCategorySelection={
                            handleEditCategorySelection
                          }
                          disableDragAndDrop={disableDragAndDrop}
                          selectedAutomation={selectedAutomation}
                          setSelectedAutomation={setSelectedAutomation}
                          handleCreateAutomationModalOpen={
                            handleCreateAutomationModalOpen
                          }
                        />
                      </div>
                    )}
                    {folder?.automations?.map((automation, index) => {
                      return (
                        <AutomationListItem
                          selected={selectedAutomation?.id === automation?.id}
                          setSelectedAutomation={setSelectedAutomation}
                          automation={automation as CellAutomation}
                          key={index}
                        />
                      )
                    })}

                    {folder?.automations?.length === 0 &&
                      (folder?.childCategories?.length === 0 ||
                        !folder.childCategories) && (
                        <div className="grow p-3 px-4">
                          <p className="text-sm italic text-gray-500">
                            Category is Empty
                          </p>
                        </div>
                      )}
                    <div className="p-3">
                      <Button
                        variant="outlined"
                        className="min-w-[0] !border-dashed text-left text-sm"
                        onClick={(event) => {
                          event.stopPropagation()
                          handleCreateAutomationModalOpen(folder.id)
                        }}
                      >
                        <span
                          className="line-clamp-1 px-6"
                          data-intercom-target="add-automation"
                          data-testid={`add-automation-to-${folder?.name}`}
                        >
                          {`Add Automation ${folder?.name && ` to ${folder?.name}`}`}
                        </span>
                      </Button>
                    </div>
                  </div>
                </DraggableFolder>
              </div>
            )
          })}
        </DragAndDropFolderList>
      )}
    </>
  )
}

export default AutomationFolderList
