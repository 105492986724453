import { FC } from 'react'

import {
  FindAutomationLogQuery,
  FindAutomationLogQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { CellAutomation } from 'src/components/Automations/AutomationCell'
import AutomationLogDetails from 'src/components/Automations/AutomationLogModal/AutomationLogDetails'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { AutomationBaserowData } from '../../AutomationLayout'

export const QUERY = gql`
  query FindAutomationLogQuery($id: Int!) {
    automationLog(id: $id) {
      id
      createdAt
      completedAt
      clientId
      client {
        id
        name
      }
      path
      eventId
      eventType
      automationId
      criteriaResults
      triggerData
      actionData
      status
    }
  }
`
type SuccessProps = CellSuccessProps<
  FindAutomationLogQuery,
  FindAutomationLogQueryVariables
> & {
  automation: CellAutomation
  selectedAutomationBaserowData: AutomationBaserowData
}
export type AutomationLogType = SuccessProps['automationLog'] & {
  automation: CellAutomation
}

export const Loading = () => (
  <div className="py-20">
    <LoadingSpinner />
  </div>
)

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<SuccessProps> = ({
  automationLog,
  automation,
  selectedAutomationBaserowData,
}) => {
  const enrichedAutomationLog = {
    ...automationLog,
    automation: automation,
  }

  return (
    <AutomationLogDetails
      automationLogRecord={enrichedAutomationLog}
      selectedAutomationBaserowData={selectedAutomationBaserowData}
    />
  )
}
