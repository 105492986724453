import React, { FC, useEffect, useState } from 'react'

import {
  FieldDefinitionOption,
  RequirementType,
} from '@stafflink/zod-types/src/automations'
import { BaserowField } from 'types/graphql'

import CustomRequirementComponent from 'src/components/Automations/AutomationRequirements/CustomRequirementComponent'

interface CustomInputComponentListProps {
  selectedFilter: FieldDefinitionOption
  onUpdate: (updatedReq: RequirementType | null) => void
  requirement: RequirementType
  availableFields: BaserowField[]
  fieldsToMatch?: BaserowField[]
}
export const CustomRequirementComponentList: FC<
  CustomInputComponentListProps
> = ({
  selectedFilter,
  onUpdate,
  requirement,
  availableFields,
  fieldsToMatch,
}) => {
  const [paramKeys, setParamKeys] = useState([])
  const [selectedField, setSelectedField] = useState(null)

  useEffect(() => {
    const selectedField = availableFields.find(
      (field) => field.name === requirement.fieldName,
    )

    setSelectedField(selectedField)

    if (selectedField) {
      const paramKeys = Object.keys(selectedFilter.params)
      setParamKeys(paramKeys)
    }
  }, [availableFields, requirement])

  const handleParamChange = (paramKey, newValue, valueLabel = '') => {
    const updatedFilterParams = {
      ...selectedFilter.params,
      [paramKey]: {
        ...selectedFilter.params[paramKey],
        value: newValue,
        valueLabel,
      },
    }

    const updatedRequirement = {
      ...requirement,
      filter: {
        ...selectedFilter,
        params: updatedFilterParams,
      },
    }

    onUpdate(updatedRequirement)
  }

  return (
    <>
      {paramKeys?.map((paramKey) => (
        <CustomRequirementComponent
          key={paramKey}
          paramKey={paramKey}
          paramValue={selectedFilter.params[paramKey]}
          handleChange={handleParamChange}
          allParams={selectedFilter.params}
          selectOptions={selectedField?.select_options}
          collaborators={selectedField?.collaboratorData}
          fieldsToMatch={fieldsToMatch}
        />
      ))}
    </>
  )
}

export default CustomRequirementComponentList
