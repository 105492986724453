import React, { FC } from 'react'

const DetailTitle: FC<{
  children: React.ReactNode
  alignRight?: boolean
  setWidth?: boolean
}> = ({ children, alignRight = false, setWidth = false }) => {
  return (
    <p
      className={`pb-2 text-xs font-light text-gray-500 ${
        alignRight && 'text-right'
      } ${setWidth && 'min-w-[200px]'}`}
    >
      {children}
    </p>
  )
}

export default DetailTitle
