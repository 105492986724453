export const CREATE_AUTOMATION_CATEGORY = gql`
  mutation CreateAutomationCategory($input: CreateAutomationCategoryInput!) {
    createAutomationCategory(input: $input) {
      id
    }
  }
`

export const DELETE_AUTOMATION_CATEGORY = gql`
  mutation DeleteAutomationCategory($id: Int!) {
    deleteAutomationCategory(id: $id) {
      id
    }
  }
`

export const UPDATE_AUTOMATION_CATEGORY = gql`
  mutation UpdateAutomationCategory(
    $id: Int!
    $input: UpdateAutomationCategoryInput!
  ) {
    updateAutomationCategory(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_AUTOMATION_CATEGORY_RANK = gql`
  mutation UpdateAutomationCategoryRank($input: UpdateRanksInput!) {
    rankLearnerItems(input: $input) {
      id
    }
  }
`

export const CREATE_AUTOMATION = gql`
  mutation CreateAutomation(
    $inputData: CreateAutomationInput!
    $baserowData: BaserowBaseDataInput
  ) {
    createAutomation(inputData: $inputData, baserowData: $baserowData) {
      id
      name
      description
      onCreateTrigger
      onUpdateTrigger
      onDeleteTrigger
      triggerType
      matchCriteria
      action
      active
      automationResourceId
      automationCategoryId
      automationRunsThisMonth
      lastRun
      isValidConfig {
        valid
        requirements
        checks
      }
    }
  }
`

export const GET_AUTOMATION_DETAILS = gql`
  query GetAutomationDetails($id: Int!) {
    automation(id: $id) {
      id
      name
      description
      active
      createdAt
      updatedAt
      automationCategoryId
      createdByUser {
        id
        name
        avatarUrl
      }
      updatedByUser {
        id
        name
        avatarUrl
      }
    }
    automationCategories {
      id
      name
      childCategories {
        id
        name
      }
    }
  }
`

export const GET_BASEROW_WORKSPACES_BASES_TABLES_LIST = gql`
  query GetBaserowWorkspacesBasesTablesList {
    getBaserowWorkspaceAndBaseList {
      workspaceId
      workspaceName
      databases {
        id
        name
        inUseElsewhere
        tables {
          id
          name
        }
      }
    }
  }
`

export const DELETE_AUTOMATION = gql`
  mutation DeleteAutomation($id: Int!) {
    deleteAutomation(id: $id) {
      id
    }
  }
`

export const UPDATE_AUTOMATION = gql`
  mutation UpdateAutomation(
    $id: Int!
    $inputData: UpdateAutomationInput!
    $baserowData: BaserowBaseDataInput
  ) {
    updateAutomation(
      id: $id
      inputData: $inputData
      baserowData: $baserowData
    ) {
      id
    }
  }
`

export const TOGGLE_AUTOMATION = gql`
  mutation ToggleAutomation($id: Int!, $input: UpdateAutomationInput!) {
    toggleAutomation(id: $id, input: $input) {
      id
      active
    }
  }
`

export const GET_AUTOMATION_LOG = gql`
  query GetAutomationLog($automationId: Int!, $pagination: Int!) {
    automationLogsByAutomationId(
      automationId: $automationId
      pagination: $pagination
    ) {
      id
      status
      clientId
      createdAt
      completedAt
    }
  }
`

export const GET_BASEROW_VIEWS = gql`
  query GetBaserowTableViews($input: BaserowTableViewInput!) {
    getBaserowTableViews(input: $input) {
      id
      name
      slug
    }
  }
`
export const GET_BASEROW_FIELDS = gql`
  query GetBaserowTableFields($input: BaserowTableFieldInput!) {
    getBaserowTableFields(input: $input) {
      id
      name
      type
      select_options {
        id
        value
        color
      }
      collaboratorData {
        id
        name
        email
        user_id
      }
      formula_type
      link_row_table_id
      date_force_timezone
      date_include_time
      array_formula_type
      order
      primary
      read_only
      table_id
    }
  }
`

export const GET_ACTIVE_MEMBERSHIPS = gql`
  query MembershipsByClient($activeOnly: Boolean!) {
    membershipsByClient(activeOnly: $activeOnly) {
      id
      user {
        id
        name
        email
      }
    }
  }
`
