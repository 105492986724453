import React, { FC } from 'react'

import Stack from '@mui/material/Stack'
import { Action } from '@stafflink/zod-types/src/automations'
import { BaserowField } from 'types/graphql'

import { getTableFieldName } from 'src/components/Automations/AutomationLogModal/utils'

import DetailValue from './DetailView'

interface ActionItemRowProps {
  fieldIdFull: string
  value?: string
  baserowTableFields: BaserowField[]
  automationLogRelatedAutomationAction: Action[]
}

const ActionItemRow: FC<ActionItemRowProps> = ({
  fieldIdFull,
  value,
  baserowTableFields,
  automationLogRelatedAutomationAction,
}) => {
  if (!fieldIdFull) return
  return (
    <Stack direction={'row'} spacing={2}>
      <DetailValue setWidth>
        {getTableFieldName({
          fieldIdFull,
          automationLogRelatedAutomationAction,
          baserowTableFields,
        })}
      </DetailValue>
      <DetailValue setWidth>{value || '-'}</DetailValue>
    </Stack>
  )
}

export default ActionItemRow
