import { FC, Fragment } from 'react'

import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { FunnelIcon } from '@heroicons/react/24/solid'
import { Step, StepContent, StepLabel } from '@mui/material'
import { AutomationTriggerType } from '@stafflink/zod-types/src/automations'

import Button from 'src/components/Library/Button/Button'

import { CellAutomation } from '../AutomationCell'
import AutomationEmptyDisplay from '../AutomationEmptyDisplay'
import { SettingsSidebarEditState } from '../AutomationLayout'

import AutomationTrackFilter from './AutomationTrackFilter'
import { ValueChip, ValueChipTheme } from './AutomationTrackValueChip'
import ClearAutomationInputButton, {
  ClearType,
} from './ClearAutomationInputButton'

interface AutomationTrackProps {
  automation: CellAutomation
  handleOpenEdit: (editMode: SettingsSidebarEditState) => void
}

const AutomationTrack: FC<AutomationTrackProps> = ({
  automation,
  handleOpenEdit,
}) => {
  // An empty JSON response is an object, but a saved result is an array of "anything"
  // Check to see if any actions have been saved
  let automationHasFilter = false

  // Check if the automation has views or requirements AND is set to corresponding mode
  const automationHasViews =
    automation?.matchCriteria?.['RECORD']?.['useViewsAsFilters'] &&
    automation?.matchCriteria?.['RECORD']?.['selectedView']?.value

  const automationHasRequirements =
    !automation?.matchCriteria?.['RECORD']?.['useViewsAsFilters'] &&
    automation?.matchCriteria?.['RECORD']?.['requirements']?.length > 0

  if (automationHasViews || automationHasRequirements) {
    automationHasFilter = true
  }

  return (
    <Step active expanded>
      <StepLabel
        className="px-0"
        icon={<FunnelIcon className="ml-1 h-5 w-5 text-gray-400" />}
      >
        <div className="ml-1 flex items-center justify-between gap-4">
          <span className="px-3 py-1 text-xl text-gray-500">Filters</span>
          <ClearAutomationInputButton
            clearItem={ClearType.FILTER}
            automationId={automation.id}
            disabled={
              !automationHasFilter || automation?.automationCategoryId === null
            }
          />
        </div>
      </StepLabel>
      <StepContent className="pb-6">
        <div className="pl-4">
          <p className="pb-2 pt-2 font-light italic text-gray-500">
            When the record matches filters..
          </p>
          {automation?.triggerType !== AutomationTriggerType.FIELD ? (
            <>
              {automationHasFilter ? (
                <>
                  <div className="pt-2">
                    {automation.matchCriteria['RECORD']?.[
                      'useViewsAsFilters'
                    ] && (
                      <p className="relative w-full rounded border border-gray-300 bg-white p-4 px-3">
                        <span className="mr-2 font-light">
                          Record enters view
                        </span>
                        <ValueChip
                          value={
                            automation.matchCriteria['RECORD']?.[
                              'selectedView'
                            ]?.['label']
                          }
                          theme={ValueChipTheme.GREEN}
                        />
                      </p>
                    )}
                    {!automation.matchCriteria['RECORD']?.[
                      'useViewsAsFilters'
                    ] && (
                      <AutomationTrackFilter
                        filterRequirements={
                          automation.matchCriteria['RECORD']?.['requirements']
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                <AutomationEmptyDisplay
                  title="No Filters Set"
                  subtitle="This automation will run on all triggers on the table."
                  icon={
                    <ShieldExclamationIcon className="mx-auto h-16 w-16 pb-4" />
                  }
                />
              )}
              <Button
                disabled={automation?.automationCategoryId === null}
                variant="outlined"
                className="mt-3 min-w-[0] !border-dashed"
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenEdit(SettingsSidebarEditState.FILTER)
                }}
                buttonDataTestId="automation-filters-button"
              >
                {automationHasFilter ? 'Edit' : 'Add'} Filters
              </Button>
            </>
          ) : (
            <AutomationEmptyDisplay
              title="Filters not required,"
              subtitle="The trigger options set will act as filters"
              icon={
                <ShieldExclamationIcon className="mx-auto h-16 w-16 pb-4" />
              }
            />
          )}
        </div>
      </StepContent>
    </Step>
  )
}

export default AutomationTrack
