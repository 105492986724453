import React, { FC } from 'react'

import { AutomationLogCriteriaResultsType } from '@stafflink/zod-types/src/automations'

import BaserowRecordFieldList from 'src/components/Automations/AutomationLogModal/Components/BaserowRecordFieldList'
import Accordion from 'src/components/Library/Accordion'

import BaserowRecordFieldDifferentialList from './BaserowRecordFieldDifferentialList'

interface BaserowTriggerRecordListsProps {
  automationLogCriteriaResults: AutomationLogCriteriaResultsType
}

const BaserowTriggerRecordLists: FC<BaserowTriggerRecordListsProps> = ({
  automationLogCriteriaResults,
}) => {
  return (
    <Accordion
      removeDetailsPadding
      title={<p className="font-light">Trigger Details</p>}
      customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
      borderstyle="!border before:hidden !rounded-0 !overflow-hidden"
    >
      <div className="bg-gray-50">
        <BaserowRecordFieldList
          data={automationLogCriteriaResults.oldData}
          title="Original Data"
        />
        <BaserowRecordFieldList
          data={automationLogCriteriaResults.newData}
          title="Updated Data"
        />
        <BaserowRecordFieldDifferentialList
          data={automationLogCriteriaResults}
        />
      </div>
    </Accordion>
  )
}

export default BaserowTriggerRecordLists
