import React, { FC } from 'react'

const DetailValue: FC<{
  children: React.ReactNode
  alignRight?: boolean
  setWidth?: boolean
}> = ({ children, alignRight = false, setWidth = false }) => {
  return (
    <p
      className={`pb-1 text-sm ${alignRight && 'text-right'} ${
        setWidth && 'min-w-[200px]'
      }`}
    >
      {children}
    </p>
  )
}

export default DetailValue
