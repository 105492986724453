import React, { FC } from 'react'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import Stack from '@mui/material/Stack'
import { AutomationLogCriteriaResultItemType } from '@stafflink/zod-types/src/automations'

import Accordion from 'src/components/Library/Accordion'

import DetailValue from './DetailView'

interface BaserowRecordFieldListProps {
  data: AutomationLogCriteriaResultItemType
  title: string
  level?: number
}

const BaserowRecordFieldList: FC<BaserowRecordFieldListProps> = ({
  data,
  title,
}): JSX.Element => {
  if (!data || !data.results) return null
  return (
    <Accordion
      removeDetailsPadding
      title={<p className="font-light">{title}</p>}
      customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
      borderstyle="!border-0 before:hidden !rounded-0 !overflow-hidden"
    >
      <div className="border-b bg-gray-50 p-4">
        <Stack direction={'row'} spacing={2} className="mb-2 border-b pb-1">
          <DetailValue setWidth>
            <b>Field</b>
          </DetailValue>
          <DetailValue setWidth>
            <b>Value</b>
          </DetailValue>
          <DetailValue setWidth>
            <b>Match</b>
          </DetailValue>
        </Stack>

        {data.results.map((item, index) => (
          <Stack key={index} direction={'row'} spacing={2}>
            {item.fieldName && (
              <>
                <DetailValue setWidth>
                  {`${
                    item.fieldValue === 'None'
                      ? 'New Record'
                      : `${item?.fieldName ?? ''}`
                  }`}
                </DetailValue>
                <DetailValue setWidth>
                  {`${item.fieldValue || '-'}`}
                </DetailValue>

                <DetailValue setWidth>
                  <div className="flex items-center gap-2">
                    {item.success ? (
                      <CheckCircleIcon
                        className={'h-4 w-4 rounded-lg text-green-400'}
                      />
                    ) : (
                      <XCircleIcon
                        className={'h-4 w-4 rounded-lg text-amber-400'}
                      />
                    )}

                    {`${item.success ? 'Match' : 'Mismatch'}`}
                  </div>
                </DetailValue>
              </>
            )}
          </Stack>
        ))}
      </div>
    </Accordion>
  )
}

export default BaserowRecordFieldList
