import React, { FC, useState } from 'react'

import 'dayjs/locale/en-au' // Import the Australian English locale
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  ArrowTopRightOnSquareIcon,
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid2 as Grid,
  Tooltip,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { AutomationLog } from 'types/graphql'

import {
  AutomationLogGroup,
  CellAutomation,
} from 'src/components/Automations/AutomationCell/AutomationCell'
import AutomationLogDetailModalCell from 'src/components/Automations/AutomationLogModal/AutomationLogDetailCell/'
import Button from 'src/components/Library/Button/Button'

interface IntegrationProps {
  automationLogs: AutomationLogGroup[]
}

export const getAppropriateDurationFormat = (
  createdAt: string,
  completedAt: string,
): string => {
  const createdAtLocale = dayjs(createdAt).locale('en-au')
  const completedAtLocale = dayjs(completedAt).locale('en-au')
  const timeDifference = completedAtLocale.diff(createdAtLocale)

  if (!timeDifference) {
    return '-'
  }

  let elapsed: string = ''
  if (timeDifference >= 86400000) {
    // 1 day in milliseconds
    elapsed = (timeDifference / 86400000).toFixed(2) + 'd'
  } else if (timeDifference >= 3600000) {
    // 1 hour in milliseconds
    elapsed = (timeDifference / 3600000).toFixed(2) + 'h'
  } else if (timeDifference >= 60000) {
    // 1 minute in milliseconds
    elapsed = (timeDifference / 60000).toFixed(2) + 'm'
  } else if (timeDifference >= 1000) {
    // 1 second in milliseconds
    elapsed = (timeDifference / 1000).toFixed(2) + 's'
  } else {
    elapsed = timeDifference.toFixed(2) + 'ms'
  }
  return elapsed
}

const AutomationLogSection: FC<IntegrationProps> = ({ automationLogs }) => {
  const [expandedAutomationName, setExpandedAutomationName] =
    useState<string>('')
  const [selectedRecordId, setSelectedRecordId] = useState<number>(null)
  const [selectedAutomation, setSelectedAutomation] =
    useState<CellAutomation>(null)
  const [drawOpen, setDrawOpen] = useState(false)

  const handleAccordionClick = (AutomationName: string | null) => {
    setExpandedAutomationName((prevAutomationName) =>
      prevAutomationName === AutomationName ? null : AutomationName,
    )
  }

  return (
    <div className="m-4 rounded-md bg-white p-4 shadow">
      {automationLogs.map((automationDetail: AutomationLogGroup) => {
        return (
          <Accordion
            key={automationDetail.automation.name}
            expanded={
              automationDetail.automation.name === expandedAutomationName
            }
            onChange={() =>
              handleAccordionClick(automationDetail.automation.name)
            }
          >
            <AccordionSummary
              className="rounded border border-solid border-gray-300"
              expandIcon={
                <ChevronDownIcon className="h-5 w-5 cursor-pointer" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  flexDirection: 'column', // Display details in a column
                  alignItems: 'flex-start', // Align details to the left
                },
              }}
            >
              <Grid container spacing={2} className={'px-4 py-2'}>
                <Grid size={{ xs: 5, sm: 5 }}>
                  <Typography variant="h6">{`${automationDetail.automation.name} (${automationDetail.automationRecords.length})`}</Typography>
                </Grid>
                <Grid size={{ xs: 7, sm: 7 }}>
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Divider orientation="vertical" flexItem />
                    <Tooltip
                      title={
                        automationDetail.hasErrors
                          ? 'Some automations failed'
                          : 'All automations passed'
                      }
                    >
                      <div>
                        {automationDetail.hasErrors ? (
                          <XCircleIcon
                            className={'h-8 w-8 rounded-lg text-red-600'}
                          />
                        ) : (
                          <CheckCircleIcon
                            className={'h-8 w-8 rounded-lg text-green-600'}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {/* Render Integration Resources */}
                {automationDetail.automationRecords.map(
                  (automationRecord: AutomationLog, id: number) => (
                    <Grid
                      container
                      key={id}
                      className={'px-10 py-4'}
                      spacing={2}
                    >
                      <Grid size={{ xs: 4, sm: 4 }}>
                        <Stack direction="row" spacing={1}>
                          <Tooltip
                            title={`Automation ${automationRecord.status}`}
                          >
                            <div>
                              {automationRecord.status === 'COMPLETED' && (
                                <CheckCircleIcon
                                  className={
                                    'h-6 w-6 rounded-lg text-green-600'
                                  }
                                />
                              )}
                              {automationRecord.status === 'FAILED' && (
                                <XCircleIcon
                                  className={'h-6 w-6 rounded-lg text-red-600'}
                                />
                              )}
                              {automationRecord.status === 'RUNNING' && (
                                <BoltIcon
                                  className={'h-6 w-6 rounded-lg text-blue-600'}
                                />
                              )}
                            </div>
                          </Tooltip>
                          <Typography>
                            {`Automation ${automationRecord.status}`}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid size={{ xs: 2, sm: 2 }}>
                        <Typography>
                          {dayjs(automationRecord.createdAt)
                            .locale('en-au')
                            .format('DD/MM/YYYY [at] h:mm A')}
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 3, sm: 3 }}></Grid>
                      <Grid size={{ xs: 2, sm: 2 }}>
                        <Typography>
                          {getAppropriateDurationFormat(
                            automationRecord.createdAt,
                            automationRecord.completedAt,
                          )}
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 1, sm: 1 }}>
                        <Tooltip title={'View Details'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="text"
                              className="min-w-[0px] rounded-full p-2 text-gray-500"
                              onClick={() => {
                                setSelectedRecordId(automationRecord.id)
                                setSelectedAutomation(
                                  automationDetail.automation,
                                )
                                setDrawOpen(true)
                              }}
                            >
                              <ArrowTopRightOnSquareIcon
                                className={'h-6 w-6 rounded-lg text-blue-600'}
                              />
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ),
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )
      })}
      <Drawer
        open={drawOpen}
        anchor="right"
        onClose={() => setDrawOpen(false)}
        sx={{ width: '50%', flexShrink: 0 }}
        PaperProps={{ sx: { width: '50%' } }}
      >
        <AutomationLogDetailModalCell
          id={selectedRecordId}
          automation={selectedAutomation}
          selectedAutomationBaserowData={null}
        />
      </Drawer>
    </div>
  )
}

export default AutomationLogSection
