import React, { FC, useEffect, useState } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ParamType } from '@stafflink/zod-types/dist/automations'
import dayjs from 'dayjs'
import { BaserowField } from 'types/graphql'

import { FieldWrapper } from 'src/components/Automations/AutomationRequirements/RequirementComponent'
import {
  BaserowCollaborator,
  BaserowSelectOption,
} from 'src/components/Automations/utils'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import {
  AutocompleteSelectedChip,
  AutocompleteSelectedOption,
  SelectOption,
} from './AutocompleteComponents'

interface CustomInputComponentProps {
  paramKey: string
  paramValue: ParamType
  handleChange: (key: string, value: any, valueLabel?: string) => void
  allParams: Record<string, any>
  selectOptions?: BaserowSelectOption[]
  collaborators?: BaserowCollaborator[]
  fieldsToMatch?: BaserowField[]
}

const CustomRequirementComponent: FC<CustomInputComponentProps> = ({
  paramKey,
  paramValue,
  handleChange,
  allParams,
  selectOptions = [],
  collaborators,
  fieldsToMatch,
}) => {
  const [enrichedParamData, setEnrichedParamData] = useState<any>(null) // Not previously typed

  // Input Options
  const [customOptions, setCustomOptions] = useState<SelectOption[]>([])
  const [fieldOptions, setFieldOptions] = useState<SelectOption[]>([])
  const [selectOptionsFormatted, setSelectOptionsFormatted] = useState<
    SelectOption[]
  >([])
  const [collaboratorOptionsFormatted, setCollaboratorOptionsFormatted] =
    useState<SelectOption[]>([])

  // Input Type States
  // Single Select
  const [singleSelectValue, setSingleSelectValue] = useState<SelectOption>(null)
  const [singleSelectInputValue, setSingleSelectInputValue] =
    useState<string>('')

  // Multiple Select
  const [multiSelectValue, setMultiSelectValue] = useState<SelectOption[]>([])
  const [multiSelectInputValue, setMultiSelectInputValue] = useState<string>('')

  // Custom Select
  const [singleOptionSelectValue, setSingleOptionSelectValue] =
    useState<string>('')
  const [singleOptionSelectInputValue, setSingleOptionSelectInputValue] =
    useState<string>('')

  // Collaborator Select
  const [collaboratorSelectValue, setCollaboratorSelectValue] = useState<
    SelectOption[]
  >([])
  const [collaboratorSelectInputValue, setCollaboratorSelectInputValue] =
    useState<string>('')

  const [selectedFieldInput, setSelectedFieldInput] = useState<string>('')

  useEffect(() => {
    if (collaborators) {
      setCollaboratorOptionsFormatted(
        collaborators.map((option) => {
          return {
            value: option.user_id,
            label: option.name,
          }
        }),
      )
    }
  }, [collaborators])

  useEffect(() => {
    if (selectOptions) {
      setSelectOptionsFormatted(
        selectOptions.map((option) => {
          return {
            value: option.id.toString(),
            label: option.value,
            color: option.color,
          }
        }),
      )
    }
  }, [selectOptions])

  useEffect(() => {
    if (paramValue?.isConditional) {
      const dependency = paramValue.dependency
      const dependentValue = allParams[dependency.reference]?.value
      const condition = dependency.conditions[dependentValue]

      setEnrichedParamData({
        ...paramValue,
        dataType: condition?.dataType,
        type: 'input',
        label: dependentValue,
      })
    } else {
      setEnrichedParamData(paramValue)
    }

    if (paramValue?.type === 'options') {
      setCustomOptions(
        paramValue.options.map((option) => {
          return {
            value: option,
            label: option,
          }
        }),
      )

      setSingleOptionSelectValue(paramValue?.value)
    }
    if (paramValue?.type === 'dynamic') {
      setFieldOptions(
        fieldsToMatch?.map((option) => {
          return {
            value: option.id.toString(),
            label: option.name.toString(),
          }
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (
      paramValue?.dataType === 'single_select' &&
      selectOptionsFormatted.length > 0
    ) {
      const selectedOption = selectOptionsFormatted.find(
        (option) => option.value === paramValue.value,
      )
      setSingleSelectValue(selectedOption)
    } else if (
      paramValue?.dataType === 'multiple_select' &&
      selectOptionsFormatted.length > 0
    ) {
      const selectedOptions = selectOptionsFormatted.filter((option) =>
        paramValue.value.includes(option.value),
      )
      setMultiSelectValue(selectedOptions)
    } else if (
      paramValue?.dataType === 'multiple_collaborators' &&
      collaboratorOptionsFormatted.length > 0
    ) {
      const selectedOptions = collaboratorOptionsFormatted.filter((option) =>
        paramValue.value.includes(option.value),
      )
      setCollaboratorSelectValue(selectedOptions)
    }
  }, [paramValue, selectOptionsFormatted, collaboratorOptionsFormatted])

  // // PUSH THE STATE UP
  interface SendDataToParentObject {
    data: any
    label?: string
  }
  const sendDataToParentObject = ({
    data,
    label = '',
  }: SendDataToParentObject) => {
    handleChange(paramKey, data, label)
  }

  return (
    <FieldWrapper key={paramKey}>
      <Stack className={'w-full'}>
        {paramValue?.type === 'input' && (
          <>
            {enrichedParamData?.dataType === 'single_select' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  disabled={selectOptionsFormatted?.length === 0}
                  error={!singleSelectValue?.value}
                >
                  <InputLabel
                    htmlFor="select-create-Value"
                    className="-mt-1.5 text-sm font-light !text-gray-400"
                  >
                    {selectOptionsFormatted?.length === 0 &&
                      'No Values available'}
                  </InputLabel>
                  <Autocomplete
                    value={singleSelectValue}
                    onChange={(_event: any, newValue: SelectOption) => {
                      setSingleSelectValue(newValue)

                      // Save the new select
                      sendDataToParentObject({
                        data: newValue.value,
                        label: newValue.label,
                      })
                    }}
                    inputValue={singleSelectInputValue}
                    onInputChange={(_event, newInputValue) => {
                      setSingleSelectInputValue(newInputValue)
                    }}
                    options={selectOptionsFormatted}
                    size="small"
                    id="select-create-Value"
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value?.value
                    }
                    renderOption={(props, option: SelectOption) => (
                      <AutocompleteSelectedOption
                        props={props}
                        option={option}
                      />
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!singleSelectValue?.value}
                        placeholder={
                          selectOptionsFormatted?.length &&
                          !singleSelectValue?.value
                            ? 'Select a Value'
                            : ''
                        }
                      />
                    )}
                  />
                  <FormHelperText>
                    {!singleSelectValue?.value && 'Select a Value'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {enrichedParamData?.dataType === 'multiple_select' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  disabled={selectOptionsFormatted?.length === 0}
                  error={multiSelectValue?.length === 0}
                >
                  <InputLabel
                    htmlFor="select-create-Value"
                    className="-mt-1.5 text-sm font-light !text-gray-400"
                  >
                    {selectOptionsFormatted?.length === 0 &&
                      'No Options available'}
                  </InputLabel>
                  <Autocomplete
                    value={multiSelectValue}
                    onChange={(_event: any, newValue: SelectOption[]) => {
                      setMultiSelectValue(newValue)

                      // Save the new selects
                      const saveMulti = newValue.map((option) => option.value)
                      const labelMulti = newValue
                        .map((option) => option.label)
                        .join(', ')
                      sendDataToParentObject({
                        data: saveMulti,
                        label: labelMulti,
                      })
                    }}
                    inputValue={multiSelectInputValue}
                    onInputChange={(_event, newInputValue) => {
                      setMultiSelectInputValue(newInputValue)
                    }}
                    options={selectOptionsFormatted}
                    size="small"
                    id="select-create-Value"
                    multiple
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value.value
                    }
                    renderTags={(value: readonly SelectOption[], getTagProps) =>
                      value.map((option: SelectOption, index: number) => (
                        <AutocompleteSelectedChip
                          key={option.value}
                          option={option}
                          props={getTagProps}
                          index={index}
                        />
                      ))
                    }
                    renderOption={(props, option: SelectOption) => (
                      <AutocompleteSelectedOption
                        props={props}
                        option={option}
                      />
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={multiSelectValue?.length === 0}
                        placeholder={
                          selectOptionsFormatted?.length &&
                          multiSelectValue?.length === 0
                            ? 'Select an Option'
                            : ''
                        }
                      />
                    )}
                  />
                  <FormHelperText>
                    {multiSelectValue?.length === 0 && 'Select an Option'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {(enrichedParamData?.dataType === 'text' ||
              enrichedParamData?.dataType === 'long_text' ||
              enrichedParamData?.dataType === 'email' ||
              enrichedParamData?.dataType === 'formula' ||
              enrichedParamData?.dataType === 'url' ||
              enrichedParamData?.dataType === 'phone_number') && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  error={!enrichedParamData?.value}
                >
                  <TextField
                    id="select-create-Value"
                    placeholder={'Value'}
                    variant="outlined"
                    value={enrichedParamData?.value}
                    onChange={(e) => handleChange(paramKey, e.target.value)}
                    error={!enrichedParamData?.value}
                    size={'small'}
                  />
                  <FormHelperText>
                    {!enrichedParamData?.value && 'Enter a Value'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {enrichedParamData?.dataType === 'date' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  error={!enrichedParamData?.value}
                >
                  <DatePicker
                    value={dayjs(enrichedParamData?.value)}
                    onChange={(newValue) =>
                      handleChange(paramKey, newValue.toISOString())
                    }
                    slots={{
                      textField: TextField,
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        id: 'startDate',
                        name: 'startDate',
                        error: !enrichedParamData?.value,
                      },
                    }}
                  />
                  <FormHelperText>
                    {!enrichedParamData?.value && 'Enter a date'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {enrichedParamData?.dataType === 'float' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  error={!enrichedParamData?.value}
                >
                  <TextField
                    label={enrichedParamData.label}
                    variant="outlined"
                    value={enrichedParamData?.value ?? ''}
                    onChange={(e) =>
                      handleChange(paramKey, parseFloat(e.target.value))
                    }
                    type="number"
                    size={'small'}
                    error={!enrichedParamData?.value}
                  />
                  <FormHelperText>
                    {!enrichedParamData?.value && 'Enter a Value'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {(enrichedParamData?.dataType === 'integer' ||
              enrichedParamData?.dataType === 'rating' ||
              enrichedParamData?.dataType === 'count' ||
              enrichedParamData?.dataType === 'duration') && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  error={!enrichedParamData?.value}
                >
                  <TextField
                    placeholder={
                      enrichedParamData.label ? enrichedParamData.label : 0
                    }
                    variant="outlined"
                    value={enrichedParamData?.value ?? ''}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value, 10)
                      if (!isNaN(newValue)) {
                        handleChange(paramKey, newValue)
                      }
                    }}
                    type="number"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    error={!enrichedParamData?.value}
                  />
                  <FormHelperText>
                    {!enrichedParamData?.value && 'Enter a Value'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {enrichedParamData?.dataType === 'number' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  error={!enrichedParamData?.value}
                >
                  <TextField
                    placeholder={
                      enrichedParamData.label ? enrichedParamData.label : 0
                    }
                    variant="outlined"
                    value={enrichedParamData?.value ?? ''}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value)
                      if (!isNaN(newValue)) {
                        handleChange(paramKey, e.target.value)
                      }
                    }}
                    error={!enrichedParamData?.value}
                    type="number"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  <FormHelperText>
                    {!enrichedParamData?.value && 'Enter a Value'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}

            {paramValue?.dataType === 'multiple_collaborators' && (
              <FormInputRow label="Value">
                <FormControl
                  className="w-full"
                  disabled={collaboratorOptionsFormatted?.length === 0}
                  error={collaboratorSelectValue?.length === 0}
                >
                  <InputLabel
                    htmlFor="select-create-Value"
                    className="-mt-1.5 text-sm font-light !text-gray-400"
                  >
                    {collaboratorOptionsFormatted?.length === 0 &&
                      'No Options available'}
                  </InputLabel>
                  <Autocomplete
                    value={collaboratorSelectValue}
                    onChange={(_event: any, newValue: SelectOption[]) => {
                      setCollaboratorSelectValue(newValue)

                      // Save the new selects
                      const saveMulti = newValue.map((option) => option.value)
                      const labelMulti = newValue
                        .map((option) => option.label)
                        .join(', ')
                      sendDataToParentObject({
                        data: saveMulti,
                        label: labelMulti,
                      })
                    }}
                    inputValue={collaboratorSelectInputValue}
                    onInputChange={(_event, newInputValue) => {
                      setCollaboratorSelectInputValue(newInputValue)
                    }}
                    options={collaboratorOptionsFormatted}
                    size="small"
                    id="select-option"
                    multiple
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value.value
                    }
                    renderTags={(value: readonly SelectOption[], getTagProps) =>
                      value.map((option: SelectOption, index: number) => (
                        <AutocompleteSelectedChip
                          key={option.value}
                          option={option}
                          props={getTagProps}
                          index={index}
                        />
                      ))
                    }
                    renderOption={(props, option: SelectOption) => (
                      <AutocompleteSelectedOption
                        props={props}
                        option={option}
                        avatar
                      />
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          collaboratorOptionsFormatted?.length &&
                          collaboratorSelectValue?.length === 0
                            ? 'Select an Option'
                            : ''
                        }
                        error={collaboratorSelectValue?.length === 0}
                      />
                    )}
                  />
                  <FormHelperText>
                    {collaboratorSelectValue?.length === 0 &&
                      'Select an Option'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            )}
          </>
        )}
        {['options'].includes(enrichedParamData?.type) && (
          <FormInputRow label="Value">
            <FormControl
              className="w-full"
              disabled={customOptions?.length === 0}
              error={!singleOptionSelectValue}
            >
              <InputLabel
                htmlFor="select-create-Value"
                className="-mt-1.5 text-sm font-light !text-gray-400"
              >
                {customOptions?.length === 0 && 'No Values available'}
              </InputLabel>
              <Autocomplete
                value={singleOptionSelectValue}
                onChange={(_event: any, newValue: any) => {
                  setSingleOptionSelectValue(newValue.value)

                  // Save the new select
                  sendDataToParentObject({ data: newValue.value })
                }}
                inputValue={singleOptionSelectInputValue}
                onInputChange={(_event, newInputValue) => {
                  setSingleOptionSelectInputValue(newInputValue)
                }}
                options={customOptions}
                size="small"
                id="select-option"
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                renderOption={(props, option: any) => (
                  <AutocompleteSelectedOption props={props} option={option} />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!singleOptionSelectValue}
                    placeholder={
                      customOptions?.length && !singleOptionSelectValue
                        ? 'Select a Value'
                        : ''
                    }
                  />
                )}
              />
              <FormHelperText>
                {!singleOptionSelectValue && 'Select a Value'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>
        )}

        {['dynamic'].includes(enrichedParamData?.type) && (
          <FormInputRow label="Matching Field">
            <FormControl
              className="w-full"
              disabled={fieldOptions?.length === 0}
              error={!enrichedParamData?.value}
            >
              <InputLabel
                htmlFor="select-create-Filter"
                className="-mt-1.5 text-sm font-light !text-gray-400"
              >
                {fieldOptions?.length === 0 && 'No Filters available'}
              </InputLabel>
              <Autocomplete
                value={fieldOptions?.find(
                  (option) => option.value === enrichedParamData.value,
                )}
                onChange={(_event: any, newValue: SelectOption) => {
                  handleChange(paramKey, newValue.value)
                }}
                inputValue={selectedFieldInput}
                onInputChange={(_event, newInputValue) => {
                  setSelectedFieldInput(newInputValue)
                }}
                options={fieldOptions}
                size="small"
                id="select-filed"
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                disabled={fieldOptions?.length === 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!enrichedParamData?.value}
                    placeholder={
                      fieldOptions?.length && !enrichedParamData?.value
                        ? 'Select a Filter'
                        : ''
                    }
                  />
                )}
              />
              <FormHelperText>
                {!enrichedParamData?.value && 'Select a Filter'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>
        )}
      </Stack>
    </FieldWrapper>
  )
}

export default CustomRequirementComponent
